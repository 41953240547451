<template>
  <div>
    <v-card
      v-if="isNew"
      flat
      class="my-4"
    >
      <v-card-actions>
        <v-autocomplete
          v-model="selectedLocality"
          :items="localityList"
          rounded
          clearable
          hide-no-data
          return-object
          item-value="id"
          outlined
          :disabled="!!form.id"
          dense
          color="#8BD118"
          item-text="title"
          :multiple="false"
          persistent-hint
          :loading="localityQuerySearchSearching"

          :search-input.sync="localityQuerySearch"
          loader-height="5"
          hide-selected
          :hide-details="!wrongSelection"
          label="Поиск населенных пунктов (от 3 букв)"
          placeholder="Поиск населенных пунктов (от 3 букв)"
          :error="wrongSelection"
          :error-messages="wrongSelection ? 'Населенный пункт уже настроен': undefined"
          @change="onRegionChange"
          @click:clear="onClearSearch"
        />
      </v-card-actions>
    </v-card>

    <div
      v-if="!isNew"
      style="margin-top: 8px; border: 1px solid rgba(0, 0, 0, 0.1); padding: 4px"
    >
      <div class="">
        <div
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
        >
          <div style="max-width: 90%">
            <v-list-item dense>
              <v-list-item-title>
                <span>{{ getLocalityTitle }}</span><br>
              </v-list-item-title>
            </v-list-item>
          </div>
          <div
            style="display: none; flex-direction: row; justify-content: center; align-items: center; visibility: hidden"
          >
            <AppCombobox
              v-model="form.regionSetting[0].hasFixedPay"
              disabled
              style="width: 48px"
              @change="onHasFixedPay"
            />
            <span class="text-description">{{ $t('vacancies.grid_card.region_fix') }}</span>
          </div>
        </div>
      </div>
      <div class="vacancies-modal-from-step-main__separator modal-row-separator" />
      <div
        style="display: flex; flex-direction: row; align-items: center; margin-bottom: 64px;position: relative;"
        :style="{'justify-content': selfEmployerHourlyPayExists ? 'space-between' : 'flex-start'}"
      >
        <div style="display: none">
          <span
            class="text-description-title"
            style="color: transparent"
          >{{ '-' }}</span>
          <div class="modal__input-group modal-row">
            <span
              class="text-description modal-row-item"
              style="color: transparent"
            >{{ '-' }}</span>
          </div>
          <div>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ getLocalityTitle }}</span><br>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{ getLocalityRegionTitle }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>

        <!--        Ставка для физлица-->

        <div
          v-if="showWorkerSectionSection && selectedLocality"
          :style="{'margin-left': !selfEmployerHourlyPayExists ? '24px' : ''}"
          style="position: relative"
        >
          <span class="text-description-title">{{ 'Физлицо' }}</span>
          <div class="modal__input-group modal-row">
            <span class="text-description modal-row-item payment-label-item panel-min-height">{{ 'На руки' }}</span>
            <span
              class="text-description modal-row-item payment-label-item panel-min-height"
              style="max-width: 120px; text-align: center; display: inline-block"
            >
              {{ 'Цена услуги' }}
              <span style="font-size: 0.8em; line-height: 1">{{ '(вкл все налоги)' }}</span>
            </span>
          </div>
          <div class="modal__input-group modal-row">
            <div class="vacancies-modal-from-step-main__field-right__input text-left row-item-relative">
              <span
                v-if="!form.regionSetting[0].hasFixedPay"
                style="position: absolute; left: -45px; top: 8px"
                class="text-description modal-row-item"
              >{{ 'от' }}</span>
              <AppInput
                v-model="form.hourlyPay"
                slim
                :disabled="!form.regionSetting && form.regionSetting.length"
                :placeholder="`${form.hourlyPay} ₽`"
                :validationList="validationFields.minPay"
                :onChange="onPayChange"
                @input="onPayChange"
              />
              <span
                class="error-input-wrapper"
                :style="{color: invalidMinPay ? 'red' : 'darkgray', 'minWidth': '90px'}"
              >
                {{ `Более ${regionLegaPersonMinPay}` }}
              </span>
            </div>
            <div
              class="vacancies-modal-from-step-main__field-right__title modal-row-item"
            >
              {{ parseFloat(form.hourlyPay) >0 && regionMinPayWithTax ? `${regionMinPayWithTax} руб/ч` : `` }}
            </div>
          </div>
          <div
            v-if="!form.regionSetting[0].hasFixedPay"
            class="modal__input-group modal-row"
          >
            <div class="vacancies-modal-from-step-main__field-right__input text-left row-item-relative">
              <span
                style="position: absolute; left: -45px; top: 8px"
                class="text-description modal-row-item"
              >{{ 'до' }}</span>
              <AppInput
                v-model="form.regionSetting[0].maxPay"
                style="margin-top: 10px;"
                :inputClass="inValidMaxPay ? 'on-field-error': ''"
                slim
                :onChange="onMaxPayChange"
                :validationList="validationFields.maxPay"
                placeholder="₽"
              />
            </div>
            <div
              v-if="!form.regionSetting[0].hasFixedPay"
              class="vacancies-modal-from-step-main__field-right__title modal-row-item"
            >
              {{ regionMaxPayWithTax ? `${regionMaxPayWithTax} руб/ч` : `` }}
            </div>
          </div>
        </div>

        <!--        Ставка для СМЗ-->
        <div
          v-if="showSelfEmployedSection"
        >
          <span class="text-description-title">{{ 'Самозанятый' }}</span>
          <div class="modal__input-group modal-row">
            <span class="text-description modal-row-item payment-label-item panel-min-height">{{ 'На руки' }}</span>
            <span class="text-description modal-row-item payment-label-item panel-min-height">{{ 'Цена услуги' }}</span>
          </div>
          <div class="modal__input-group modal-row">
            <div
              class="vacancies-modal-from-step-main__field-right__input  row-item-relative"
            >
              <span
                v-if="!form.regionSetting[0].hasFixedPay"
                style="position: absolute; left: -45px; top: 8px"
                class="text-description modal-row-item"
              >{{ 'от' }}</span>
              <AppInput
                v-model="form.selfEmployerHourlyPay"
                slim
                :validationList="validationFields.selfEmployerHourlyPay"
                placeholder="₽"
                :onChange="onSelfEmploymentMinPay"
                :input-class="'input-field_not-valid'"
                @input="onSelfEmploymentMinPay"
              />
              <span
                v-if="showSelfEmployedSection"
                class="error-input-wrapper"
                :style="{color: invalidSelfEmployerHourlyPay ? 'red' : 'darkgray', 'minWidth': '90px'}"
              >
                {{ `Мин. ${regionSelfEmploymentMinPay.toFixed(2)}` }}
              </span>
            </div>
            <div
              class="vacancies-modal-from-step-main__field-right__title modal-row-item"
            >
              {{ regionSelfEmploymentMinPayWithTax ? `${regionSelfEmploymentMinPayWithTax} руб/ч` : `` }}
            </div>
          </div>
          <div class="modal__input-group modal-row">
            <div
              v-if="!form.regionSetting[0].hasFixedPay"
              class="vacancies-modal-from-step-main__field-right__input row-item-relative"
            >
              <span
                style="position: absolute; left: -45px; top: 8px"
                class="text-description modal-row-item"
              >{{ 'до' }}</span>
              <AppInput
                v-model="form.regionSetting[0].maxSelfEmployerPay"
                style="margin-top: 10px"
                :inputClass="selfEmploymentMaxPayInvalid ? 'on-field-error': ''"
                slim
                :onChange="onMaxSelfEmployerPay"
                :validationList="validationFields.maxSelfEmployerPay"
                placeholder="₽"
              />
            </div>
            <div
              v-if="!form.regionSetting[0].hasFixedPay"
              class="vacancies-modal-from-step-main__field-right__title modal-row-item"
            >
              {{ regionSelfEmploymentMaxPayWithTax ? `${regionSelfEmploymentMaxPayWithTax} руб/ч` : `` }}
            </div>
          </div>
        </div>

        <p
          v-if="form.hourlyPay"
          style="position: absolute; bottom: -84px; left: 0; right: 0; color: red;font-size: 0.8em; text-align: center"
        >
          Правила сервиса: цена самозанятым на 23% выше, чем оплата на руки физ
          лицу, из-за самостоятельной оплаты налога и накопления пенсии
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import AppInput from '@/components/common/simple/AppInput'
import taxMixin from '@/mixins/taxMixin.vue'
import { isNonNull } from '@/utils/validation'
import AppCombobox from '@/components/common/simple/AppCombobox'
import AppWorkerTypeUtil from '@/utils/worker-type-util'

export const MIN_REGION_RATE = 50
export const RegionTitleSuffix = '(Все населенные пункты)'

export default {
  name: 'VacancyProfessionRegionSettingForm',
  components: {
    AppInput,
    AppCombobox,
  },
  mixins: [taxMixin],
  props: {
    isPrivate: {
      type: Boolean,
    },
    form: {
      type: Object,
      default () {
        return {
          token: 'token-0',
          id: null,
          hourlyPay: '',
          selfEmployerHourlyPay: '',
          professionRegionSetting: null,
          isValid: false,
          regionSetting: [{
            hasFixedPay: this.hasFixedPay || true,
            maxPay: '',
            maxSelfEmployerPay: '',
          }]
        }
      }
    },
    vacancieCardData: {
      type: Object,
      default () {
        return {
          regionSetting: [{
            hasFixedPay: false,
            hourlyPay: '',
            maxPay: '',
            region: ''
          }]
        }
      }
    },
    closable: {
      type: Boolean,
    },
    regionsList: {
      type: Array,
      default () {
        return []
      }
    },
    professions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data (vm) {
    return {
      localityList: [],
      localityQuerySearch: '',
      lastQuerySearch: null,
      localityQuerySearchSearching: false,
      selectedLocality: null,
      wrongSelection: false,
      validationFields: undefined,
      region: '',
    }
  },
  computed: {
    hasLegalWorkerMinRateError () {
      return parseFloat(this.form.minPay) <= this.regionLegaPersonMinPay
    },
    isNew () {
      return !this.form.professionRegionSetting || !this.form.professionRegionSetting.id
    },
    getLocalityTitle () {
      const { professionRegionSetting } = this.form
      if (!professionRegionSetting || !professionRegionSetting.id) {
        return null
      }

      const { region, locality } = professionRegionSetting
      if (locality) {
        // return `${locality.title} (${region.title})`
        return `${locality.title}`
      }
      return `${region.title} ${RegionTitleSuffix}`
    },
    getLocalityRegionTitle () {
      const { professionRegionSetting } = this.form
      if (!professionRegionSetting || !professionRegionSetting.id) {
        return null
      }
      const { region, locality } = professionRegionSetting
      if (!locality) {
        return `${RegionTitleSuffix}`
      }
      return region.title
    },
    getVacancyLocalityIdList () {
      return this.vacancieCardData.regionSetting.map((item) => {
        if (item.locality && item.locality.id) {
          return item.locality.id
        }

        return item.region.id
      })
    },
    getSortedRegionList () {
      const self = this
      const currentList = self.regionsList.sort((a, b) => {
        if (a.title > b.title) {
          return 1
        }
        if (a.title < b.title) {
          return -1
        }
        return 0
      })

      if (this.selectedLocality) {
        const selectedInList = currentList.find((region) => region.id === self.selectedLocality.id)

        if (!selectedInList) {
          currentList.push(self.selectedLocality)
        }
      }
      return currentList
    },
    isSelfEmployedWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_RU_SELF_EMPLOYED
    },
    isSimpleWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_LEGAL_PERSON
    },
    isAllWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_ALL
    },
    showSelfEmployedSection () {
      return this.selfEmployerHourlyPayExists &&
        (this.isSelfEmployedWorkerType || this.isAllWorkerType || !this.getWorkerType || !this.isPrivate)
    },
    showWorkerSectionSection () {
      return this.isSimpleWorkerType || this.isAllWorkerType || !this.getWorkerType || !this.isPrivate
    },
    selfEmployerHourlyPayExists () {
      const value = this.form.hourlyPay
      return parseFloat(value) >= parseFloat(this.regionMinPay)
    },
    selfEmploymentMaxPayInvalid () {
      const value = this.form.regionSetting[0].maxSelfEmployerPay
      if (parseFloat(value) > 0) {
        return parseFloat(value) <= parseFloat(this.form.selfEmployerHourlyPay)
      }
      return true
    },
    inValidMaxPay () {
      return false
    },
    regionMinPay () {
      return MIN_REGION_RATE
    },
    invalidMinPay () {
      return !parseFloat(this.form.hourlyPay) || (parseFloat(this.form.hourlyPay) < this.regionLegaPersonMinPay)
    },
    invalidSelfEmployerHourlyPay () {
      return !this.form.selfEmployerHourlyPay ||
        (parseFloat(this.form.selfEmployerHourlyPay) < parseFloat(`${this.regionSelfEmploymentMinPay}`))
    },
    regionSelfEmploymentMinPay () {
      let pay = parseFloat(this.form.hourlyPay)
      if (!pay) {
        return 0
      }
      if (pay < this.regionLegaPersonMinPay) {
        pay = this.regionLegaPersonMinPay
      }
      return parseFloat((pay * 1.23).toFixed(2))
    },
    regionLegaPersonMinPay () {
      if (!this.selectedLocality) {
        return 0
      }
      return MIN_REGION_RATE
    },
    regionMinPayWithTax () {
      return parseFloat(this.calculateAmountTax(parseFloat(this.form.hourlyPay))) + 1
    },
    regionMaxPayWithTax () {
      return this.calculateAmountTax(parseFloat(this.form.regionSetting[0].maxPay))
    },
    regionSelfEmploymentMinPayWithTax () {
      const val = parseFloat(this.form.selfEmployerHourlyPay)
      if (isNaN(val) || !val) {
        return 0
      }
      return val + 1
    },
    regionSelfEmploymentMaxPayWithTax () {
      const val = parseFloat(this.form.regionSetting[0].maxSelfEmployerPay)
      if (isNaN(val) || !val) {
        return 0
      }
      return val + 1
    },
    isFormValid () {
      if (!this.form.regionSetting || !this.form.regionSetting.length) {
        return false
      }
      let validationList = this.validationFields
      if (this.form.regionSetting.length && this.form.regionSetting[0].hasFixedPay) {
        validationList = { ...this.validationFields, maxPay: [], maxSelfEmployerPay: [] }
      }

      let state
      let validator = true
      for (const [key, propValidatorList] of Object.entries(validationList)) {
        let curr
        switch (key) {
        case 'minPay':
          curr = this.form.hourlyPay
          break
        case 'maxSelfEmployerPay':
          curr = this.form.regionSetting[0].maxSelfEmployerPay
          break
        case 'maxPay':
          curr = this.form.regionSetting[0].maxPay
          break
        case 'selfEmployerHourlyPay':
          curr = this.form.selfEmployerHourlyPay
          break
        }
        state = propValidatorList.every((validatorData) => {
          validatorData.valid = validatorData.validator(curr)
          return validatorData.valid
        })
        if (!state) {
          validator = false
        }
      }

      const self = this

      self.form.isValid = validator

      return validator
    },
    isEdit () {
      return this.vacancieCardData.id
    },
    regionPlaceholder () {
      return this.$t('vacancies.grid_card.region')
    },
    regionValue () {
      if (!this.selectedLocality) {
        return ''
      }

      return this.selectedLocality
    },
    regionVisualValue () {
      if (!this.selectedLocality) {
        return ''
      }
      return this.selectedLocality.title
    },
    hasFixedPay () {
      if (this.vacancieCardData.id) {
        return this.vacancieCardData.regionSetting[0].hasFixedPay
      }
      return false
    },
    regionTitle () {
      if (this.selectedLocality && this.selectedLocality.id) {
        return this.selectedLocality.title
      }
      return ''
    },
    currentUser () {
      return this.$store.state.users.info
    },
    hasWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return !!this.currentUser.organization && !!this.currentUser.organization.workerType
    },
    getWorkerType () {
      if (!this.hasWorkerType) {
        return undefined
      }

      return this.currentUser.organization.workerType
    },
  },
  watch: {
    'localityQuerySearch': function (val) {
      this.autocompleteSearch(val)
    },
    'selectedLocality': function (val) {
      // if (val) {
      //   if (val.region && val.region.id) {
      //     this.localityPaymentSetting.professionPaymentSetting = val.region
      //   } else {
      //     this.localityPaymentSetting.professionPaymentSetting = val
      //   }
      // } else {
      //   this.localityPaymentSetting.professionPaymentSetting = {
      //     professionPaymentSetting: null,
      //     legalWorkerPaymentTax: '',
      //     legalWorkerPayment: '',
      //     selfEmployedPayment: '',
      //     selfEmployedPaymentTax: '',
      //   }
      // }
    }
  },
  created () {
    // TODO check
    // const selectedLocality = this.form.regionSetting
    // if (selectedLocality.length && selectedLocality[0].id) {
    //   this.selectedLocality = this.form.regionSetting[0].region
    // }
    const workerRuleSet = {
      minPay: [
        {
          text: '',
          valid: false,
          alias: 'min pay isNotMinValue',
          validator: (value) => {
            return parseFloat(value) > this.regionLegaPersonMinPay
          }
        },
        {
          alias: 'min pay isNonNull',
          text: '', valid: false,
          validator: isNonNull
        },
      ],
      maxPay: [
        {
          text: '', valid: false,
          alias: 'max pay isNotMinValue',
          validator: (value) => {
            return parseFloat(value) > parseFloat(this.form.hourlyPay)
          }
        },
      ],
    }
    const selfEmployedRuleSet = {
      selfEmployerHourlyPay: [
        {
          text: '',
          valid: false,
          alias: 'selfEmployerHourlyPay pay isNotMinValue',
          validator: (value) => {
            return parseFloat(`${value}`) > parseFloat(`${this.regionSelfEmploymentMinPay}`)
          }
        },
        {
          alias: 'min pay isNonNull',
          text: '', valid: false,
          validator: isNonNull
        },
      ],
      maxSelfEmployerPay: [
        {
          text: '', valid: false,
          alias: 'maxSelfEmployerPay pay isNotMinValue',
          validator: (value) => {
            return parseFloat(value) > parseFloat(this.regionSelfEmploymentMinPay) &&
              parseFloat(value) > parseFloat(this.form.selfEmployerHourlyPay)
          }
        },
      ],
    }

    if (this.isPrivate && this.isSelfEmployedWorkerType) {
      this.selfEmployerHourlyPayExists = true
      this.validationFields = selfEmployedRuleSet
    } else if (this.isPrivate && this.isSimpleWorkerType) {
      this.validationFields = workerRuleSet
    } else {
      this.validationFields = {
        ...workerRuleSet,
        ...selfEmployedRuleSet,
      }
    }
  },
  mounted () {
    if (this.vacancieCardData.id) {
      // if (this.vacancieCardData.regionSetting.length) {
      //   // TODO править может быть много регионов
      //   this.form.regionSetting = [this.vacancieCardData.regionSetting[0]]
      //   this.region = this.vacancieCardData.regionSetting[0]
      //   if (
      //     !this.vacancieCardData.regionSetting[0].maxPay ||
      //     parseFloat(this.form.regionSetting[0].maxPay) < this.region.payPerHour
      //   ) {
      //     // this.form.regionSetting[0].maxPay = this.region.payPerHour
      //   }
      // }
    }
    this.$emit('onRegionSettingValid', this.isFormValid)
    setTimeout(() => {
      this.$emit('onRegionSettingValid', this.isFormValid)
      this.$forceUpdate()
    }, 1000)
  },
  methods: {
    calculateAmountTax (amount) {
      const val = parseFloat(amount)
      if (isNaN(val) || !val) {
        return 0
      }
      return (this.calculateTax(val) + (val)).toFixed(2)
    },

    initRegionSetting () {
      if (this.regionsList && this.regionsList.length) {
        // this.region = this.regionsList[0]
        // this.onRegionChange(this.region)
      }
    },
    onRegionChange ($event) {
      if (!$event) {
        return
      }
      if (this.getVacancyLocalityIdList.includes($event.id)) {
        this.wrongSelection = true
        return
      }

      let currLocality = null
      let currRegion = $event
      if ($event.region) {
        currRegion = $event.region
        currLocality = {
          id: $event.id,
          title: $event.title,
        }
      }

      const price = 50
      this.form.professionRegionSetting = {
        id: $event.id,
        minPay: price,
        region: currRegion,
        locality: currLocality
      }

      this.form.regionSetting = [
        {
          hasFixedPay: true,
          maxPay: '',
          maxSelfEmployerPay: '',
          region: {
            title: ''
          }
        },
      ]
      // this.form.hourlyPay = price
      this.form.hourlyPay = price
      this.form.minPay = price
      this.form.regionSetting[0].maxPay = price
    },
    onHasFixedPay ($event) {
      this.$emit('onRegionSettingValid', this.isFormValid)
    },
    onPayChangeInput ($event) {
      // this.$set(this.form, 'hourlyPay', pay)
      // const data = { ...this.form.regionSetting[0], maxPay: 4 }
      // this.$set(this.form, 'regionSetting', [data])
      this.$emit('onRegionSettingValid', this.isFormValid)
      // this.form.regionSetting[0].maxPay = 6
      this.$forceUpdate()
    },
    onPayChange ($event) {
      if ($event) {
        // const selfEmployerHourlyPay = parseFloat($event) * 1.23
        //
        // this.form.selfEmployerHourlyPay = selfEmployerHourlyPay.toFixed(2)
        // this.form.regionSetting[0].maxSelfEmployerPay = selfEmployerHourlyPay
        this.$emit('onRegionSettingValid', this.isFormValid)
        this.form.minPay = $event
        this.$forceUpdate()
      } else {
        this.form.selfEmployerHourlyPay = ''
        this.form.hourlyPay = ''
        this.form.regionSetting[0].maxSelfEmployerPay = ''
        this.form.regionSetting[0].maxPay = ''
      }
    },
    onSelfEmploymentMinPay () {
      this.onPayChange(this.form.minPay)
    },
    onMaxPayChange ($event) {
      this.$emit('onRegionSettingValid', this.isFormValid)
      this.$forceUpdate()
    },
    onMaxSelfEmployerPay ($event) {
      // const pay = parseFloat($event) || ''
      // const data = { ...this.form.regionSetting[0], maxSelfEmployerPay: pay }
      // this.$set(this.form, 'regionSetting', [data])
      this.$emit('onRegionSettingValid', this.isFormValid)
      this.$forceUpdate()
    },
    onClearSearch () {
      this.localityList = []
      this.localityQuerySearch = ''
      this.lastQuerySearch = null
      this.localityQuerySearchSearching = false
      this.selectedLocality = null
      this.wrongSelection = false
    },
    async autocompleteSearch (val) {
      // Items have already been loaded
      if (this.selectedLocality && this.selectedLocality.title === val) {
        return
      }

      // Items have already been requested
      if (!val || val.trim() === '' || val.length < 3) {
        return
      }
      if (this.localityQuerySearchSearching) return
      this.localityQuerySearchSearching = true

      setTimeout(async () => {
        this.localityQuerySearchSearching = true
        this.lastQuerySearch = this.localityQuerySearch
        const defaultParams = { 'page[number]': 1, 'page[size]': 250, 'searchQuery': this.lastQuerySearch.trim() }

        await this.requestLocalityList(this.setLocalityList, defaultParams)

        this.localityQuerySearchSearching = false
        if (this.localityQuerySearch !== this.lastQuerySearch) {
          await this.autocompleteSearch(this.localityQuerySearch)
        }
      }, 500)
    },
    setLocalityList (localityData) {
      if (localityData && localityData.error) {
        this.localityList = []
      } else {
        const regex = /ё/i
        this.localityList = localityData.modelList.map((item) => {
          const title = item.region ? `${item.title} (${item.region.title})` : `${item.title} ${RegionTitleSuffix}`
          return {
            ...item,
            title: title.replace(regex, 'е')
            // $clone = str_replace('е', 'ё', $clone);
          }
        }).sort((a, b) => {
          if (a.title > b.title) {
            return 1
          }
          if (a.title < b.title) {
            return -1
          }
          return 0
        })
      }
    },
    async requestApiListData (action, params) {
      try {
        const response = await this.$store.dispatch(action, params)
        let paginatorTotal
        const modelList = response.data
        if (response.headers['x-total-count']) {
          paginatorTotal = parseInt(response.headers['x-total-count'], 10)
        } else {
          paginatorTotal = 50
        }

        return {
          modelList: modelList || [],
          total: paginatorTotal,
        }
      } catch (error) {
        console.log(error)
        return {
          modelList: null,
          total: null,
          error: true,
        }
      }
    },
    async requestLocalityList (callback, params = {}) {
      const defaultParams = { 'page[number]': 1, 'page[size]': 250 }
      if (!params) {
        params = defaultParams
      }
      if (!params['page[number]']) {
        params['page[number]'] = 1
      }
      if (!params['page[number]']) {
        params['page[size]'] = 250
      }
      const localityList = await this.requestApiListData(
        'users/getLocalityList', { params })

      if (callback) {
        callback(localityList)
      }
      return localityList
    },
    onDeleteItem () {
      this.$emit('onDeleteEntry', this.form)
    }
  }
}
</script>
<style lang="sass">
.panel-min-height
  min-height: 40px
.error-input-wrapper
  color: red
  font-size: 0.6em
  position: absolute
  bottom: -9px
  left: 10px
.on-field-error
  border-color: red !important
.text-description-title
  font-style: normal
  font-weight: 800
  font-size: 14px
  line-height: 17px
  color: #000000
  padding-left: 60px
.text-description
  font-size: 14px
  line-height: 17px
  color: #000000
  opacity: 0.8
.modal-row
  margin: 1px 0 0 !important
  padding: 0!important
.modal-row-separator
  margin: 4px auto !important
.modal-row-item
  min-width: 100px
  padding-left: 15px
.payment-label-item
  margin-bottom: 4px
.row-item-relative
  position: relative
.vacancies-modal-from-step-main
  &__field-right
    display: flex
    justify-content: space-between
    align-items: center
    margin: 20px 0
    position: relative

    &__title-require
      min-width: 120px
      position: absolute
      top: -61px
      @include fontStyle(500, 13px)
      opacity: .3

    &__title
      @include fontStyle(500, 14px)

    &__title-question
      @include fontStyle(800, 16px)
      opacity: .3

    &__input
      width: 70px
      margin: 0 15px

    &__description
      width: 65px
      @include fontStyle(500, 12px)
      opacity: .2

</style>
