<template>
  <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
    <label
      class="switch small"
      @click="onDisabledToggle"
    >
      <input
        type="checkbox"
        :checked="checkbox ? true : undefined"
        :disabled="disabled"
        @click="toggleCheckbox"
      >
      <div
        :class="{'inactive': disabled}"
        class="slider round"
      />
    </label>
    <label style="margin-left: 16px;">Вакансия только для своих</label>
  </div>
</template>

<script>
export default {
  name: 'AppSwitch',
  props: {
    remoteValue: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      checkbox: false
    }
  },
  computed: {

  },
  watch: {
    checkbox: function (val) {
      this.$emit('onAppSwitchValueChange', this.checkbox)
    }
  },
  created () {
    this.checkbox = !!this.remoteValue
  },
  methods: {
    toggleCheckbox () {
      // if (this.disabled) {
      this.$emit('onAppSwitchValueChange', this.checkbox)
      if (!this.disabled) {
        this.checkbox = !this.checkbox
      }
      // }
    },
    onDisabledToggle () {
      if (this.disabled) {
        this.$emit('onAppSwitchValueChange', null)
      }
    }
  },
}
</script>

<style type="text/scss" scoped>

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
//width: 30px;
//height: 17px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8BD118;
}

input:focus + .slider {
  background-color: #8BD118;
}

input:checked + .slider:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

.slider.round {
  border-radius: 28px;
}

.slider.inactive {
  opacity: 0.3;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
