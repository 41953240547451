<template>
  <div class="vacancies-modal-from-step-main">
    <div
      style="margin-top: 8px"
      class="vacancies-modal-from-step-main__field-right__title-question"
    >
      Укажите вопросы для соискателя, которые он увидит при отклике на вакансию, без ответов на вопросы,
      он не сможет отправить отклик. Все ответы вы увидите в чате с соискателем в разделе отклики
    </div>
    <div class="vacancies-modal-from-step-main__field-right">
      <div class="vacancies-modal-from-step-main__field-right__title-require" />
      <div class="vacancies-modal-from-step-main__field-right__title-question">
        {{ questionsTitle }}
      </div>
    </div>
    <div
      v-for="(question, ndx) in questionList"
      :key="ndx"
      class="vacancies-modal-from-step-main__field"
    >
      <AppTextArea
        v-model="question['description']"
        :field-id="question.id"
        placeholder="Введите вопрос соискателю"
        :onChange="onChange"
        @input="onInput"
        @clear="onClear(ndx, question.id)"
      />
    </div>
    <IconPlus
      class="vacancies-modal-from-step-main__plus"
      @click.native="addNewQuestion"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import AppTextArea from '@/components/common/simple/AppTextArea'
import IconPlus from '@/components/common/icons/IconPlus'

export default {
  name: 'VacanciesModalFormStepQuestions',
  components: {
    AppTextArea,
    IconPlus
  },
  props: {
    vacancieCardData: {
      type: Object,
      default () {
        return {}
      }
    },
    getParamsFlag: {
      type: Boolean,
      default: false
    },
    vacancyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentFieldId: '',
      currentDescription: '',
      maxQuestionsCount: 5,
      questionList: []
    }
  },
  computed: {
    questionsTitle () {
      return `${this.questionList.length}/${this.maxQuestionsCount}`
    },
    canAddQuestion () {
      const filled = this.questionList.filter((item) => !item.description || !item.description.trim().length)
      return filled.length === 0 && this.questionList.length < this.maxQuestionsCount
    },
    formValid () {
      const filled = this.questionList.filter((item) => item.description && item.description.trim().length)
      return filled.length > 0
    }
  },
  watch: {
    getParamsFlag (flag) {
      if (flag) {
        this.$emit('onGetParamsQuestions', true)
      }
    }
  },
  created () {
    this.addNewQuestion()
    if (this.vacancieCardData.questionList && this.vacancieCardData.questionList.length) {
      this.questionList = this.vacancieCardData.questionList
    }
  },
  methods: {
    ...mapActions({
      addQuestionById: 'vacancy/addQuestionById',
      removeQuestionById: 'vacancy/removeQuestionById',
      editQuestionById: 'vacancy/editQuestionById'
    }),
    getQuestionList (data) {
      return this.questionList
    },
    onInput (data) {
      this.currentDescription = data
    },
    onUpdate (id) {
      this.currentFieldId = id
      this.editQuestionById({
        id: this.currentFieldId,
        description: this.currentDescription
      })
    },
    addNewQuestion () {
      if (this.canAddQuestion) {
        const newQuestion = { description: '', priority: this.questionList.length + 1 }
        this.questionList.push(newQuestion)
      }
    },
    onChange ($event) {
      this.questionList = this.questionList.filter((item) => item.description && item.description.trim().length > 0)
      this.$emit('onFormValid', this.formValid)
    },
    onClear (ndx, id) {
      if (this.questionList.length > 1) {
        this.$emit('onDeleteQuestion', id)
        this.$emit('onFormValid', true)
        this.questionList = this.questionList.filter((item, index) => ndx !== index)
      }
    }
  }
}
</script>
<style>
</style>
