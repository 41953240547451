<template>
  <div class="vacancies-modal-from-step-main">
    <div style="margin-top: 16px; margin-bottom: 32px">
      <div class="vacancies-modal-from-step-main__field">
        <v-expansion-panels
          v-model="competencePanel"
          class="elevation-0"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header> Профессия и наименование</v-expansion-panel-header>
            <v-expansion-panel-content>
              <AppSwitch
                v-if="form && form.init"
                :remote-value="form.isPrivate"
                :disabled="!hasWorkerType || vacancieCardData.isComplete"
                @onAppSwitchValueChange="onAppSwitchValueChange"
              />

              <div
                style="display: flex; flex-direction: row;
      margin-top: 8px;
      justify-content: flex-start; align-items: center"
              >
                <div
                  style="width: 4px; height: 18px;
        font-style: normal;
        font-weight: 300 !important;
        background-color: #8BD118; margin-right: 16px"
                />
                <span
                  v-if="form.isPrivate"
                  style="color: #ccc"
                >Эту вакансию будут видеть только ваши исполнители</span>
                <span
                  v-else
                  style="color: #ccc"
                >Эту вакансию будут видеть все!</span>
              </div>
              <div
                style="position: relative"
              >
                <AppFloatingModal
                  v-if="alertData.message"
                  :message="alertData.message"
                  :action-name="alertData.actionName"
                  :enabled="enableAlertData"
                  :action-callback="alertData.callback"
                  @appFloatingModalClose="onDisableAlertData"
                />
              </div>

              <div class="vacancies-modal-from-step-main__field">
                <div class="vacancies-modal-from-step-main__field">
                  {{ vacanciePlaceholder }}
                </div>
                <AppInput
                  v-model="form.title"
                  :placeholder="vacanciePlaceholder"
                />
              </div>
              <div class="vacancies-modal-from-step-main__separator" />
              <div class="vacancies-modal-from-step-main__field">
                <div class="vacancies-modal-from-step-main__field">
                  {{ vacancieExternalTitlePlaceholder }}
                </div>
                <AppInput
                  v-model="form.externalTitle"
                  :placeholder="vacancieExternalTitlePlaceholder"
                />
              </div>
              <div class="vacancies-modal-from-step-main__separator" />
              <AppSelect
                :disabled="editable"
                :value.sync="form.profession"
                :visual-value="dynamicVisualCompetence"
                :placeholder="competencePlaceholder"
                :values="professions"
              >
                <template #default="{value}">
                  <div
                    class="select-item"
                    @click="onSelectCompetence(value)"
                  >
                    {{ value.mainCompetence.title }}
                  </div>
                </template>
              </AppSelect>
              <div class="vacancies-modal-from-step-main__text-block">
                <div class="vacancies-modal-from-step-main__text-block__title">
                  {{ $t('vacancies.grid_card.profession') }}:
                </div>
                <div
                  :class="{'vacancies-modal-from-step-main__text-block__empty': !form.profession}"
                  class="vacancies-modal-from-step-main__text-block__value"
                >
                  {{ professionTitle }}
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="elevation-0">
              {{ `${getCompetencePanelTitle}` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="elevation-0 py-0 my-0">
              <div>
                <!--                <div class="vacancies-modal-from-step-main__text-block">-->
                <!--                  <div class="vacancies-modal-from-step-main__text-block__title">-->
                <!--                    {{ $t('vacancies.card.competence') }}:-->
                <!--                  </div>-->
                <!--                </div>-->
                <div style="margin-top: 32px" />
                <div
                  v-for="(compentence, ndx) in currentCompetenceList"
                  :key="compentence.id"
                  class="modal__input-group"
                  style="margin-top: 0"
                >
                  <AppCombobox
                    v-model="form['competence' + ndx]"
                    style="width: 5%"
                  />
                  <span class="modal-text">{{ compentence.title }}</span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          class="vacancies-modal-from-step-main__separator"
          style="margin-top: 20px"
        />
        <!--      <div class="">-->
        <!--        <div class="modal__input-group ">-->
        <!--          <AppCombobox-->
        <!--            v-model="form.selfEmployeeOnly"-->
        <!--            style="width: 5%"-->
        <!--          />-->
        <!--          <span class="modal-text">{{ $t('vacancies.docs.selfEmployeeOnly') }}</span>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="">
          <div class="modal__input-group ">
            <AppCombobox
              v-model="form.requiredMedicalBook"
              style="width: 5%"
            />
            <span class="modal-text">{{ $t('vacancies.docs.requiredMedicalBook') }}</span>
          </div>
        </div>
        <div class="">
          <div class="modal__input-group ">
            <AppCombobox
              v-model="form.laborerHasMaterialResponsibility"
              style="width: 5%"
            />
            <span class="modal-text">{{ $t('vacancies.docs.laborerHasMaterialResponsibility') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AppSelect from '@/components/common/simple/AppSelect'
import AppInput from '@/components/common/simple/AppInput'
import AppCombobox from '@/components/common/simple/AppCombobox'
import AppSwitch from '@/components/common/simple/AppSwitch'
import AppFloatingModal from '@/components/common/modal/AppFloatingModal'
import routerList from '@/router/labels'

export default {
  name: 'VacanciesModalFormStepMain',
  components: {
    AppInput,
    AppFloatingModal,
    AppSelect,
    AppSwitch,
    AppCombobox
  },
  props: {
    vacancieCardData: {
      type: Object,
      default () {
        return {}
      }
    },
    getParamsFlag: {
      type: Boolean,
      default: false
    },
    professions: {
      type: Array,
      default () {
        return []
      }
    },
    regions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      enableAlertData: false,
      competencePanel: [0],
      alertData: {
        message: undefined,
        actionName: undefined,
        callback: undefined,
      },
      form: {
        profession: this.vacancieCardData.id ? this.vacancieCardData.profession.mainCompetence : '',
        title: this.vacancieCardData.title || '',
        externalTitle: this.vacancieCardData.externalTitle || '',
        isPrivate: this.vacancieCardData.isPrivate || null,
        requiredMedicalBook: this.vacancieCardData.requiredMedicalBook || false,
        selfEmployeeOnly: this.vacancieCardData.selfEmployeeOnly || false,
        laborerHasMaterialResponsibility: this.vacancieCardData.laborerHasMaterialResponsibility || false
      }
    }
  },
  computed: {
    ...mapGetters({
      competenceList: 'vacancy/getCompetenceList'
    }),
    getCompetencePanelTitle () {
      let length = 0
      let selected = 0
      const title = `${this.$t('vacancies.card.competence')}`
      if (!this.currentCompetenceList) {
        return title
      }
      selected = this.currentCompetenceList.filter((item, ndx) => this.form[`competence${ndx}`]).length
      length = this.currentCompetenceList.length

      return `${title} (${selected}/${length})`
    },
    currentUser () {
      return this.$store.state.users.info
    },
    hasWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return !!this.currentUser.organization && !!this.currentUser.organization.workerType
    },
    dynamicVisualCompetence () {
      if (this.form.profession && this.form.profession.mainCompetence) {
        return this.form.profession.mainCompetence.title
      }
      return (this.form.profession && this.form.profession.title) || ''
    },
    currentCompetenceList () {
      let currentProfession = {}
      // if (this.vacancieCardData && this.vacancieCardData.competenceList &&
      //     this.vacancieCardData.competenceList.length) {
      //   return this.vacancieCardData.competenceList
      // }

      if (this.professions.length) {
        currentProfession = this.professions.find((item) => {
          return this.form.profession.id === item.mainCompetence.id
        })
      }

      if (currentProfession && currentProfession.id) {
        return currentProfession.competenceList
      } else {
        return this.form.profession.competenceList
      }
    },
    isEdit () {
      return this.vacancieCardData.id
    },
    editable () {
      return (this.vacancieCardData.id && this.vacancieCardData.id.length > 0)
    },
    competenceProfessionTitle () {
      if (this.vacancieCardData.id) {
        return this.vacancieCardData.profession.mainCompetence.title
      }

      return ''
    },
    professionTitle () {
      if (!this.isEdit) {
        if (this.form.profession) {
          return this.form.profession.title
        }
      }

      if (this.form.profession && this.form.profession.mainCompetence && this.form.profession.mainCompetence.title) {
        return this.form.profession.mainCompetence.title
      }

      return (this.vacancieCardData.id && this.vacancieCardData.profession.title) ||
        this.$t('vacancies.form.profession_clean')
    },
    vacanciePlaceholder () {
      return this.$t('Внутреннее название вакансии (Видите только вы и ваши коллеги)')
    },
    vacancieExternalTitlePlaceholder () {
      return this.$t('Внешнее название вакансии (Видят работники в приложении)')
    },
    competencePlaceholder () {
      return this.$t('vacancies.card.main_competence')
    },
    isValidForm () {
      return this.currentCompetenceList.length && !!this.form.title
    }
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        let competenceList = []

        if (!!this.form.profession && this.currentCompetenceList) {
          competenceList = this.currentCompetenceList.filter((item, ndx) => this.form[`competence${ndx}`])
        }

        const valid = !!this.form.title && competenceList.length && !!this.form.profession
        this.$emit('onFormValid', valid)
      }
    },
    getParamsFlag (flag) {
      if (flag) {
        if (this.isEdit) {
          this.$emit('onSaveVacancyDetail', {
            id: this.vacancieCardData.id,
            // office: { id: this.$route.params.id },
            title: this.form.title,
            message: this.vacancieCardData.message,
            requiredMedicalBook: this.form.requiredMedicalBook,
            selfEmployeeOnly: this.form.selfEmployeeOnly,
            externalTitle: this.form.externalTitle,
            isPrivate: this.form.isPrivate,
            laborerHasMaterialResponsibility: this.form.laborerHasMaterialResponsibility,
            profession: this.form.profession,
            competenceList: this.currentCompetenceList.filter((item, ndx) => this.form[`competence${ndx}`])
          })
        } else {
          const params = {
            id: this.vacancieCardData.id,
            // office: { id: this.$route.params.id },
            title: this.form.title,
            message: this.vacancieCardData.message,
            requiredMedicalBook: this.form.requiredMedicalBook,
            selfEmployeeOnly: this.form.selfEmployeeOnly,
            externalTitle: this.form.externalTitle,
            isPrivate: this.form.isPrivate,
            laborerHasMaterialResponsibility: this.form.laborerHasMaterialResponsibility,
            profession: this.form.profession,
            competenceList: this.currentCompetenceList.filter((item, ndx) => this.form[`competence${ndx}`])
          }
          this.$emit('onSaveVacancyDetail', params)
        }
      }
    }
  },
  created () {
    this.initForm()
    if (this.vacancieCardData.id) {
      this.vacancieCardData.competenceList.forEach((item) => {
        const index = this.currentCompetenceList.findIndex((competence) => {
          return competence.id === item.id
        })
        this.$set(this.form, `competence${index}`, true)
      })

      this.form.profession = this.professions.find((item) => {
        return item.id === this.vacancieCardData.profession.id
      })
    } else {
      this.competencePanel = [0, 1]
    }

    const alertMsg = 'У вас неактивирован функционал своих исполнителей, свяжитесь ' +
      'с нашим менеджером для дополнительной информации'
    this.alertData = {
      message: alertMsg,
      actionName: 'К поддержке',
      callback: () => {
        this.onDisableAlertData()
        this.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
      }
    }
  },
  methods: {
    ...mapActions({
      setUpdateCompetenceFlag: 'vacancy/setUpdateCompetenceFlag'
    }),
    onDisableAlertData () {
      this.enableAlertData = false
    },
    initForm () {
      this.form = {
        init: true,
        profession: this.vacancieCardData.id ? this.vacancieCardData.profession.mainCompetence : '',
        title: this.vacancieCardData.title || '',
        externalTitle: this.vacancieCardData.externalTitle || '',
        isPrivate: this.vacancieCardData.isPrivate || null,
        requiredMedicalBook: this.vacancieCardData.requiredMedicalBook || false,
        selfEmployeeOnly: this.vacancieCardData.selfEmployeeOnly || false,
        laborerHasMaterialResponsibility: this.vacancieCardData.laborerHasMaterialResponsibility || false
      }
    },
    onAppSwitchValueChange (data) {
      if (this.vacancieCardData.isComplete) {
        return
      }
      if (!this.hasWorkerType) {
        this.form.isPrivate = null
        this.enableAlertData = true
      } else {
        this.form.isPrivate = !!data
      }
    },
    onSelectCompetence (value) {
      this.form = {
        profession: this.form.profession,
        title: this.form.title,
        isPrivate: this.form.isPrivate,
        init: true,
        externalTitle: this.form.externalTitle,
        selfEmployeeOnly: this.form.selfEmployeeOnly,
        requiredMedicalBook: this.form.requiredMedicalBook,
        laborerHasMaterialResponsibility: this.form.laborerHasMaterialResponsibility,
      }

      if (!value.id) {
        this.setUpdateCompetenceFlag(true)
      }
    }
  }
}
</script>
<style lang="sass">
.modal__input-group
  display: flex
  align-items: center
  margin-top: 15px
  padding-bottom: 20px

  &:last-of-type
    border: 0
    padding-bottom: 0
.select-item
  height: 35px
  padding: 5px 0
  margin: 0 15px
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  @include fontStyle(500, 14px)

.vacancies-modal-from-step-main
  &__plus
    cursor: pointer

  &__field
    margin: 15px 0

  &__separator
    height: 2px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)


  &__checkbox,
  &__checkbox-no-border
    // border-top: 1px solid rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    padding: 15px 0 20px 20px

    &_border_none
      border-bottom: none

    &__title
      @include fontStyle(500, 14px)
      margin-left: 8px
      margin-top: 4px
      cursor: pointer

  &__checkbox-no-border
    border-bottom: none

  &__text-block
    display: flex
    align-items: center
    margin: 15px 0
    padding-left: 20px

    &_margin_bottom
      margin: 25px 0

    &__title
      @include fontStyle(500, 14px)
      margin-right: 8px

    &__value
      @include fontStyle(500, 14px)

    &__empty
      @include fontStyle(500, 14px)
      opacity: .2

</style>
