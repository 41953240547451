<template>
  <div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
    <div
      class="carouselContainer"
      style="overflow: hidden"
    >
      <WBDCarousel
        v-model="slide"
        @onWBDCarouselClose="onWBDCarouselClose"
      >
        <rl-carousel-slide>
          <div
            class="border border-blue p-8 mx-8 text-center text-3xl barContainer"
          >
            <img
              class="barContainerImg"
              :alt="1"
              src="@/assets/img/carousel-1.png"
            >
          </div>
          <div class="carousel-desc-box">
            <p class="img-title">
              {{ slideData[`0`]['title'] }}
            </p>
            <div class="img-dec-box">
              <p
                v-for="(desc, index) of slideData[`0`]['desc']"
                :key="index"
                class="img-dec"
              >
                {{ `${desc}` }}
              </p>
              <p class="img-dec" />
            </div>
          </div>
        </rl-carousel-slide>
        <rl-carousel-slide>
          <div
            class="border border-red p-8 mx-8 text-center text-3xl barContainer"
          >
            <img
              class="barContainerImg"
              :alt="1"
              src="@/assets/img/carousel-2.png"
            >
          </div>
          <div class="carousel-desc-box">
            <h4 class="img-title">
              {{ slideData[`1`]['title'] }}
            </h4>
            <div class="img-dec-box">
              <p
                v-for="(desc, index) in slideData[`1`]['desc']"
                :key="index"
                class="img-dec"
              >
                {{ `${desc}` }}
              </p>
              <p class="img-dec" />
            </div>
          </div>
        </rl-carousel-slide>
        <rl-carousel-slide>
          <div
            class="border border-green p-8 mx-8 text-center text-3xl barContainer"
          >
            <img
              class="barContainerImg"
              :alt="1"
              src="@/assets/img/carousel-3.png"
            >
          </div>
          <div class="carousel-desc-box">
            <h4 class="img-title">
              {{ slideData[`2`]['title'] }}
            </h4>
            <div class="img-dec-box">
              <p
                v-for="(desc, index) in slideData[`2`]['desc']"
                :key="index"
                class="img-dec"
              >
                {{ `${desc}` }}
              </p>
              <p class="img-dec" />
            </div>
          </div>
        </rl-carousel-slide>
        <rl-carousel-slide>
          <div
            class="border border-green p-8 mx-8 text-center text-3xl barContainer"
          >
            <img
              class="barContainerImg"
              :alt="1"
              src="@/assets/img/carousel-4.png"
            >
          </div>
          <div class="carousel-desc-box">
            <h4 class="img-title">
              {{ slideData[`3`]['title'] }}
            </h4>
            <div class="img-dec-box">
              <p
                v-for="(desc, index) in slideData[`3`]['desc']"
                :key="index"
                class="img-dec"
              >
                {{ `${desc}` }}
              </p>
              <p class="img-dec" />
            </div>
          </div>
        </rl-carousel-slide>
        <!-- Slide controls go here (or above, or anywhere :) ) -->
      </WBDCarousel>
    </div>
  </div>
</template>

<script>
import WBDCarousel from '@/components/carousel/index'

export default {
  name: 'WbdCarouselSlider',
  components: {
    WBDCarousel,
  },
  data () {
    return {
      slideData: {
        '0': {
          title: 'У нас появилась новая функция “Смены для своих”',
          desc: [
            ' - это биржа смен для ваших сотрудников.',
          ],
        },
        '1': {
          title: 'Создавай вакансии для своих ',
          desc: [
            'Вы получите на 100% производительного и качественного исполнителя.',
          ],
        },
        '2': {
          title: 'Если свои не смогут выйти на смену',
          desc: [
            'То можно создать заявку для остальных пользователей по ' +
            'цене, которую вы устанавливаете для внешних исполнителей',
          ],
        },
        '3': {
          title: 'Узнайте подробности!',
          desc: [
            'Свяжитесь со своим аккаунт-менеджером или напишите в поддержку ' +
            'для получения подробностей и запуска этого проекта в вашей компании.',
          ],
        },
      },
      slide: 0,
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    onWBDCarouselClose (disable) {
      this.$emit('onWBDCarouselClose', disable)
    }
  }
}
</script>
<style type="text/scss" scoped>
  $carouselWidth: 600px;
  .carouselContainer {
    height: 650px;
    width: 100%;
    max-width: $carouselWidth;
    border: 1px solid whitesmoke;
  }
  .barContainer {
    height: 450px;
    max-height: 450px;
    width: 100%;
    max-width: $carouselWidth;
    border: 2px solid black;
    background-color: whitesmoke;
  }
  .barContainerImg {
    height: 450px;
    max-height: 450px;
  }
  .img-title {
    margin-top: 24px;
    font-size: 18px;
    color: #000000;
    font-weight: 800;
    text-align: center;
  }
  .carousel-desc-box {
    height: 150px;
    border: 1px solid whitesmoke;
    margin-bottom: 0;
  }
  .img-dec-box {
    margin-top: 20px;
    width: 100%;
    max-width: $carouselWidth;
  }
  .img-dec {
    font-size: 16px; color: #000000; opacity: 0.6;
    margin: 0;
    padding: 4px;
  }
</style>
