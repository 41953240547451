<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal || showBuyModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="currentModal = ''"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot" />

      <vacancies-filter-bar-header
        v-if="isScroll"
        :filters="filters"
        :is-edit="isEdit"
        :currentLayout="currentLayout"
        @changeStatus="onTabChange"
        @edit="isEdit = !isEdit"
        @deactivate="deactivateSelectedVacancies"
        @changeLayout="(value) => {currentLayout = value}"
        @openModal="(val) => {currentModal = val}"
        @createOffer="currentModal = modalName.createVacancie"
      />
    </template>
    <template #center>
      <vacancies-filter-bar-center
        style="margin-bottom: 10px;"
        :filters="filters"
        :loading="pageLoading"
        :is-edit="isEdit"
        :currentLayout="currentLayout"
        :selectedOffer="selected"
        @changeStatus="onTabChange"
        @edit="isEdit = !isEdit"
        @deactivate="deactivateSelectedVacancies"
        @changeLayout="(value) => {currentLayout = value}"
        @openModal="(val) => {currentModal = val}"
        @createOffer="currentModal = modalName.createVacancie"
      />

      <app-grid-list
        v-if="currentLayout === 'grid'"
        :list="vacanciesList"
        :isShowLoad="pageLoading"
        item-class="offers__grid__item"
      >
        <VacanciesGridItem
          v-for="(offer, index) in vacanciesList"
          :key="index"
          :offer="offer"
          :selectedOffer="selected"
          @click="clickVacancy"
        />
      </app-grid-list>

      <VacanciesTable
        v-else
        :keys="table.keys"
        :offersOffice="vacanciesList"
        :is-edit="isEdit"
        :paginator="paginator || 0"
        :isShowLoad="pageLoading"
        :offer-checked="selected"
        @selectCombobox="clickVacancy"
        @click="clickVacancy"
        @selectAll="selectAll"
        @onItemSelected="onItemSelected"
        @onOptionChange="onOptionChange"
      />
    </template>
    <template #modal>
      <AppModalSmall
        v-if="currentModal === modalName.removeOffer"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          {{ $t('vacancies.controls.removeConfirm', { count: selected.length }) }}
        </h1>

        <div class="modal__button">
          <AppButton
            class="button__warm-action"
            @click.native="removeSelectedVacancies"
          >
            {{ $t('vacancies.controls.remove_vacancies') }}
          </AppButton>
        </div>

        <a
          class="modal__button-cancel"
          @click.prevent="currentModal = ''"
        >
          {{ $t('vacancies.controls.cancel') }}
        </a>
      </AppModalSmall>

      <VacanciesModalForm
        v-if="currentModal === modalName.createVacancie"
        style="padding: 30px"
        @changeStatus="onTabChange"
        @close="currentModal = ''"
      />
    </template>
  </AppMainContainer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppGridList from '@/components/common/AppGridList'
import VacanciesFilterBarHeader from '@/components/vacancies/VacanciesFilterBarHeader'
import VacanciesFilterBarCenter from '@/components/vacancies/VacanciesFilterBarCenter'
import VacanciesTable from '@/components/vacancies/VacanciesTable'
import VacanciesGridItem from '@/components/vacancies/VacanciesGridItem'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import VacanciesModalForm from '@/components/vacancies/VacanciesModalForm'
import { routeList } from '@/router/vacancies'
import userUtil from '@/utils/user-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'
import { VACANCY_STORE_TYPES } from '@/store/modules/vacancy/vacancy'
// import AppLogger from '@/utils/appLogger'

const modalName = Object.freeze({
  removeOffer: 'removeOffer',
  createVacancie: 'createVacancie',
})

export { modalName }

export default {
  components: {
    PageHeader,
    AppMainContainer,
    AppGridList,
    VacanciesFilterBarHeader,
    VacanciesFilterBarCenter,
    VacanciesTable,
    VacanciesGridItem,
    AppModalSmall,
    AppButton,
    VacanciesModalForm
  },
  data () {
    return {
      modelList: [],
      pageSize: null,
      paginator: null,
      filters: {
        statusGroup: 'active',
      },
      pageLoading: true,
      currentLayout: 'table',
      isEdit: false,
      isScroll: false,
      selected: [],

      table: {
        keys: [
          { label: '', value: 'employers', config: { canSort: false } },
          { label: 'Внутреннее название', value: 'title', config: { canSort: true } },
          { label: 'Внешнее название', value: 'externalTitle', config: { canSort: true } },
          { label: this.$t('vacancies.table.profession'), value: 'profession.title', config: { canSort: true } },
          { label: 'Регионы', value: 'regionTitle', config: { canSort: false } },
          {
            label: this.$t('vacancies.table.sum_hour'),
            value: 'laborerPayment',
            alignRight: true,
            config: { canSort: false }
          },
          // {
          //   label: this.$t('vacancies.table.sum_hour_max'),
          //   value: 'payMax',
          //   alignRight: true,
          //   config: { canSort: false }
          // },
          {
            label: this.$t('vacancies.table.self_employment_sum_hour'),
            value: 'selfEmployeePayment',
            alignRight: true,
            config: { canSort: false }
          },
          {
            label: 'Для своих',
            value: 'forRemoteStaff',
            alignRight: true,
            config: { canSort: false }
          },
          // {
          //   label: this.$t('vacancies.table.max_self_employment_sum_hour'),
          //   value: 'maxSelfEmployerPay',
          //   alignRight: true,
          //   config: { canSort: false }
          // }
        ]
      },

      currentModal: '',
      showBuyModal: false,
      modalName
    }
  },
  computed: {
    ...mapGetters({
      tabsCountData: 'vacancy/getTabsCountData'
    }),
    isGridView () {
      return this.currentLayout === 'grid'
    },
    officeUser () {
      return this.$store.state.office.userOffice
    },
    root () {
      const user = this.$store.state.users.info
      return user && user.id && userUtil.isRoot(user)
    },
    vacanciesList () {
      const list = this.modelList
      const mapSelfemployeeTitle = (vacancy) => {
        const selfEmployeePaySet = []
        const laborerPaySet = []
        const regionList = []
        if (vacancy.regionSetting.length) {
          const regionSettingList = vacancy.regionSetting

          for (const regionSetting of regionSettingList) {
            if (regionSetting && regionSetting.region && regionSetting.region.id) {
              const localityTitle = regionSetting.locality ? regionSetting.locality.title : regionSetting.region.title

              const selfEmployerPay = regionSetting.selfEmployerPay
              let maxSelfEmployerPay
              let laborerMaxPay
              const laborerPay = regionSetting.payPerHour
              if (regionSetting.hasFixedPay) {
                maxSelfEmployerPay = ''
                laborerMaxPay = ''
              } else {
                maxSelfEmployerPay = ` - ${regionSetting.maxSelfEmployerPay}`
                laborerMaxPay = ` - ${regionSetting.maxPay}`
              }

              selfEmployeePaySet.push(`[${localityTitle}, ${selfEmployerPay}${maxSelfEmployerPay}]`)
              laborerPaySet.push(`[${localityTitle}, ${laborerPay}${laborerMaxPay}]`)


              regionList.push(localityTitle)
            }
          }
        }

        return {
          selfEmployeePayment: selfEmployeePaySet.join(`, `),
          laborerPayment: laborerPaySet.join(`, `),
          laborerPaymentCounter: laborerPaySet.length,
          selfEmployeePaymentCounter: selfEmployeePaySet.length,
          regionTitle: regionList.join(', '),
          regionTitleCounter: regionList.length,
        }
      }


      return list.map((item) => ({
        ...item,
        forRemoteStaff: item.isPrivate ? 'Да' : 'Нет',
        ...mapSelfemployeeTitle(item)
      }))
    },
    currentUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        // if (this.pageSize && !this.pageLoading) {
        //   this.getList(1, this.pageSize)
        // }
      }
    },
    isEdit: {
      handler (val) {
        if (!val) this.selected = []
      }
    }
  },
  beforeDestroy () {
    this.clearModelList()
  },
  created () {
    if (this.isGridView) {
      this.getList(1, 100)
    }
  },
  methods: {
    ...mapActions({
      fetchVacancyList: 'vacancy/getVacancyList',
      removeVacancies: 'vacancy/removeVacancies',
      deactivateVacancies: 'vacancy/deactivateVacancies',
      activateVacancies: 'vacancy/activateVacancies',
    }),
    clearModelList () {
      this.$store.commit('vacancy/setVacancyItems', [])
      this.modelList = []
    },
    onTabChange (filterData) {
      this.AppLogger.info(filterData, 'filterData')
      this.clearModelList()
      this.selected = []
      this.filters.statusGroup = filterData.statusGroup
      this.isEdit = false
      if (this.pageSize && !this.pageLoading) {
        this.getList(1, this.pageSize, filterData)
      }
    },
    onOptionChange (options) {
      this.clearModelList()
      this.getList(options.page, options.itemsPerPage)
    },
    async getList (page, size, filters) {
      try {
        const pagination = { 'page[number]': page, 'page[size]': size }
        this.pageLoading = true
        this.pageSize = size

        let params = {
          workflowLevel: this.filters.statusGroup,
          ...pagination,
        }

        if (filters) {
          params = {
            ...params,
            ...filters
          }
        }
        const response = await this.$store.dispatch(
          'vacancy/getVacancyList',
          { params })

        this.$store.commit(`vacancy/${VACANCY_STORE_TYPES.SET_TABS_COUNT_DATA}`, {
          data: {
            activeCount: response.headers['x-active-count'] || 0,
            inactiveCount: response.headers['x-inactive-count'] || 0,
            draftCount: response.headers['x-draft-count'] || 0,
            totalCount: response.headers['x-total-count'] || 0,
          }
        })

        this.modelList = response.data.map((item) => ({
          ...item,
        }))

        if (response.headers['x-total-count']) {
          this.paginatorTotal = parseInt(response.headers['x-total-count'], 10)
        } else {
          this.paginatorTotal = this.footerProps.itemsPerPageOptions[1]
        }
      } catch (error) {
        this.pageLoading = false
      } finally {
        this.paginatorStat()
        this.pageLoading = false
      }
    },
    selectAll (isSelected) {
      if (this.isEdit) {
        if (isSelected) {
          this.selected = []
          this.selected = this.vacanciesList.map((offer) => offer.id)
        } else {
          this.selected = []
        }
      }
    },

    onItemSelected (selectedList) {
      this.AppLogger.info(selectedList, 'VVVVVVV')
      if (this.isEdit) {
        this.selected = selectedList
      }
    },

    clickVacancy (vacancy) {
      if (this.isEdit) {
        const findVacancy = this.selected.findIndex((id) => id === vacancy.id)
        if (findVacancy === -1) {
          this.selected.push(vacancy.id)
        } else {
          this.selected.splice(findVacancy, 1)
        }
      } else if (!this.isEdit) {
        this.$router.push({
          name: routeList.VACANCIES_ID_NAME,
          params: { vacancyId: vacancy.id }
        })
      }
    },
    async deactivateSelectedVacancies () {
      try {
        if (this.selected.length) {
          this.pageLoading = true
          if (this.filters.statusGroup === 'active') {
            await this.deactivateVacancies({ ids: this.selected })
              .then(() => {
                this.getList(1, this.pageSize)

                this.isEdit = !this.isEdit
              })
          } else {
            await this.activateVacancies({ ids: this.selected })
              .then(() => {
                this.getList(1, this.pageSize)

                this.isEdit = !this.isEdit
              })
          }
          this.pageLoading = false
        }
      } catch (error) {
        this.AppLogger.info(error)
        this.pageLoading = false
      }
    },
    paginatorStat (data) {
      // return data[this.filters.statusGroup]
      const total = parseInt(this.tabsCountData[`${this.filters.statusGroup}Count`], 10)
      this.paginator = total
    },
    removeSelectedVacancies () {
      this.removeVacancies({ ids: this.selected })
        .then(() => {
          this.fetchVacancyList({ ...this.filters, source: 'list' })
          this.currentModal = ''
        })
    }
  }
}
</script>
<style>
</style>
