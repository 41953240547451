<template>
  <app-grid-item
    class="vacancies__grid__item"
    :class="{
      'vacancies__grid__item_selected': isSelected
    }"
    style="padding: 16px !important;"
    :item="offer"
    @click="clickToOffer"
  >
    <div class="vacancies__grid__item__header">
      <IconEmployee />

      <span class="vacancies__grid__item__header__title">
        {{ offer.title }}
      </span>
    </div>

    <div class="vacancies__grid__item__period">
      <div class="vacancies__grid__item__region">
        <div class="vacancies__grid__item__period__profession__text">
          <span class="vacancies__grid__item__period__profession__text__title">
            {{ $t('vacancies.grid_card.profession') }}
          </span>:
          <span>{{ offer.profession.title }}</span>
        </div>
        <!--        <div class="vacancies__grid__item__period__simple__text">
          {{ offer.profession.title }}
        </div>-->
      </div>
    </div>

    <div class="vacancies__grid__item__info vacancies__grid__item__info_state_column">
      <div
        :class="{
          'vacancies__grid__item__period_check_not': !offer.requiredMedicalBook
        }"
        class="vacancies__grid__item__period__pay__text"
      >
        <img
          v-if="offer.requiredMedicalBook"
          src="@/assets/icon-check.svg"
        >
        {{ $t('vacancies.docs.requiredMedicalBook') }}
      </div>
      <div
        :class="{
          'vacancies__grid__item__period_check_not': !offer.laborerHasMaterialResponsibility
        }"
        class="vacancies__grid__item__period__pay__text"
      >
        <img
          v-if="offer.laborerHasMaterialResponsibility"
          src="@/assets/icon-check.svg"
        >
        {{ $t('vacancies.docs.laborerHasMaterialResponsibility') }}
      </div>
      <div
        :class="{
          'vacancies__grid__item__period_check_not': !offer.hasQuestionList
        }"
        class="vacancies__grid__item__period__pay__text"
      >
        <img
          v-if="offer.hasQuestionList"
          src="@/assets/icon-check.svg"
        >
        {{ $t('vacancies.docs.hasQuestionList') }}
      </div>
    </div>
    <div class="vacancies__grid__item__info">
      <span class="vacancies__grid__item__period__simple__text">
        <span class="vacancies__grid__item__period__profession__text__title">
          {{ $t('vacancies.grid_card.region') }}
        </span>:
        <span>{{ regionTitle }}</span>


      </span>
    </div>
    <div class="vacancies__grid__item__info vacancies__grid__item__info_bottom_none">
      <div class="vacancies__grid-column">
        <div class="vacancies__grid__item__period__pay__text">
          часовая ставка:
        </div>
        <span class="vacancies__grid__item__bottom__price-full">
          <span class="self-employment-label">Физлицо: </span>
          {{ priceOfHour }}
        </span>
        <span class="vacancies__grid__item__bottom__price-full">
          <span class="self-employment-label">Самозанятый: </span>

          {{ selfEmployerPayPerHour }}
        </span>
      </div>
      <div
        v-if="!hasFixedPay"
        class="vacancies__grid-column"
      >
        <div class="vacancies__grid__item__period__pay__text">
          Макс. часовая ставка:
        </div>
        <span class="vacancies__grid__item__bottom__price-full">
          <span class="self-employment-label">Физлицо: </span>
          {{ maxPriceOfHour }}
        </span>
        <span class="vacancies__grid__item__bottom__price-full">
          <span class="self-employment-label">Самозанятый: </span>

          {{ selfEmploymentMaxPay }}
        </span>
      </div>
    </div>
  </app-grid-item>
</template>

<script>
import AppGridItem from '@/components/common/AppGridItem'
import IconEmployee from '@/components/common/icons/IconEmployee'

export default {
  name: 'OfferGridItem',
  components: { AppGridItem, IconEmployee },
  props: {
    offer: {
      type: Object,
      required: true
    },
    selectedOffer: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currency: '₽'
    }
  },
  computed: {
    isSelected () {
      return this.selectedOffer.includes(this.offer.id)
    },
    regionTitle () {
      if (
        this.offer.regionSetting[0] &&
        this.offer.regionSetting[0].region &&
        this.offer.regionSetting[0].region.title
      ) {
        return this.offer.regionSetting[0].region.title
      }
      return '-'
    },
    priceOfHour () {
      if (this.offer.regionSetting && this.offer.regionSetting[0] && this.offer.regionSetting[0].payPerHour) {
        return `${this.offer.regionSetting[0].payPerHour} ${this.currency}`
      }
      return '-'
    },
    maxPriceOfHour () {
      if (this.offer.regionSetting && this.offer.regionSetting[0] && this.offer.regionSetting[0].maxPay) {
        return `${this.offer.regionSetting[0].maxPay} ${this.currency}`
      }
      return '-'
    },
    hasFixedPay () {
      if (this.offer.regionSetting && this.offer.regionSetting[0]) {
        return this.offer.regionSetting[0].hasFixedPay
      }
      return false
    },
    regionSettingExists () {
      return this.offer && this.offer.regionSetting && this.offer.regionSetting.length
    },
    getCurrentRegion () {
      if (!this.regionSettingExists) {
        return null
      }
      return this.offer.regionSetting[0]
    },
    selfEmploymentMaxPay () {
      if (!this.getCurrentRegion) {
        return ''
      }
      return this.getCurrentRegion.maxSelfEmployerPay
    },
    selfEmployerPayPerHour () {
      if (!this.offer) {
        return ''
      }
      return this.offer.selfEmployerHourlyPay
    },
  },
  methods: {
    clickToOffer () {
      this.$emit('click', this.offer)
    }
  }
}
</script>

<style lang="sass" scoped>
  $border-line: 1px solid rgba(0, 0, 0, 0.11)
  $default_padding-bottom: 10px

  .self-employment-label
    font-size: 0.8em
    color: rgba(0, 0, 0, 0.6)
    font-weight: normal
    min-width: 96px
    display: inline-block
  .vacancies__grid__item
    &__selected
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1000
    &__header
      display: flex
      align-items: center
      padding-bottom: 15px
      border-bottom: $border-line

      &__title
        @extend %header-2-type
        white-space: nowrap
        text-overflow: ellipsis
        margin-left: 10px
        overflow: hidden

    &__period
      margin-top: $default_padding-bottom
      display: flex
      padding-bottom: $default_padding-bottom
      border-bottom: $border-line

      &_check_not
        margin-left: 25px
        height: 25px

      &__doc__absent
        width: 25px
        height: 25px
        display: inline-block

      &__pay__text
        display: flex
        align-items: center
        @extend %date-text
        color: rgba(0, 0, 0, 0.6)
        margin-bottom: 2px

      &__simple__text
        @extend %date-text
        color: rgba(0, 0, 0, 0.6)

      &__profession__text
        @extend %date-text
        color: rgba(0, 0, 0, .5)
        font-weight: bold
        margin-bottom: 5px
        &__title
          color: black
          font-weight: 800

    &__info
      display: flex
      justify-content: space-between
      margin-top: $default_padding-bottom
      padding-bottom: $default_padding-bottom
      border-bottom: $border-line

      &_bottom_none
        border-bottom: none

      &_state_column
        flex-direction: column

      &__text
        @extend %navigation__children-item
        color: rgba(0, 0, 0, 0.8)

    &__bottom
      display: flex
      margin-top: 10px

      &__price-full
        @extend %input-text
        color: rgba(0, 0, 0, 0.91)
        margin-right: 20px
        font-weight: 800
</style>
