<template>
  <rl-carousel
    v-model="slide"
    @swipe-recognized="clearInterval"
  >
    <div slot-scope="{ wrapperStyles, slides: { count, active } }">
      <div class="overflow-hidden text-center">
        <div v-bind="wrapperStyles">
          <slot />
        </div>
      </div>
      <div>
        <div class="mt-1 text-center">
          <span
            v-for="(item, index) in count"
            :key="index"
            class="mx-1 cursor-pointer"
            @click="slide = index; clearInterval()"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1"
                y="0.5"
                width="19"
                height="19"
                rx="9.5"
                :fill="active !== index ? 'white' : '#8BD118'"
              />
              <rect
                x="1"
                y="0.5"
                width="19"
                height="19"
                rx="9.5"
                stroke="#E3E3E3"
              />
            </svg>

            <!--            <svg-->
            <!--              xmlns="http://www.w3.org/2000/svg"-->
            <!--              width="30"-->
            <!--              height="20"-->
            <!--              class="stroke-current"-->
            <!--              :class="{-->
            <!--                'text-grey': active !== index,-->
            <!--                'text-grey-dark': active === index-->
            <!--              }"-->
            <!--            >-->
            <!--              <line-->
            <!--                x1="0"-->
            <!--                y1="10"-->
            <!--                x2="30"-->
            <!--                y2="10"-->
            <!--                stroke-width="2"-->
            <!--              />-->
            <!--            </svg>-->
          </span>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between">
          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start"
          >
            <AppCombobox
              v-if="isLast(active, count)"
              v-model="hide"
              style="width: auto"
              @change="onHide"
            />
            <span
              v-if="isLast(active, count)"
              style="cursor: pointer"
              class="modal-text"

              @click.stop="onHide"
            >
              {{ 'Больше не показывать' }}
            </span>
          </div>
          <div style="text-align: right">
            <AppButton
              v-if="active > 0"
              style="height: 32px; margin-right: 24px"
              class="button-flat"
              @click.native="prev(active)"
            >
              Назад
            </AppButton>
            <AppButton
              style="margin: 0 auto; min-width: 180px"
              @click.native="next(active, count)"
            >
              {{ getNextBtnTitle (active, count) }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </rl-carousel>
</template>

<script>
import { RlCarousel } from 'vue-renderless-carousel'
import AppButton from '@/components/common/simple/AppButton'
import AppCombobox from '@/components/common/simple/AppCombobox'

export default {
  name: 'WBDCarousel',
  components: {
    RlCarousel,
    AppButton,
    AppCombobox,
  },
  data () {
    return {
      slide: 0,
      interval: undefined,
      hide: undefined,
    }
  },
  computed: {

  },
  created () {
    this.interval = setInterval(() => {this.slide += 1}, 3600000)
  },
  methods: {
    clearInterval () {
      clearInterval(this.interval)
    },
    isLast (active, count) {
      return active === (count - 1)
    },
    next (active, count) {
      if (active < (count - 1)) {
        this.slide = (active + 1)
        this.clearInterval()
      } else {
        this.$emit('onWBDCarouselClose')
      }
    },
    prev (active) {
      this.slide = (active - 1)
      this.clearInterval()
    },
    getNextBtnTitle (active, count) {
      return active < (count - 1) ? 'Далее' : 'Создать вакансию'
    },
    async onHide () {
      this.$emit('onWBDCarouselClose', true)
      try {
        this.$store.dispatch('users/manageSetting', {
          alias: 'hide-vacancy-on-boarding',
          isActive: false
        }).then(() => {
          this.$store.dispatch('users/fetchUserData')
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style type="text/scss" scoped>
svg { transition: color .5s ease; }
.stroke-current {
  stroke: currentColor;
}
.text-grey-dark {
  color: #8BD118;
}
.text-grey {
  color: #004499;
}
</style>
<!--npm install &#45;&#45;save @egjs/flicking-->
<!--vue-ssr-carousel-->
<!--vue-renderless-carousel-->
