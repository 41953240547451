<template>
  <div>
    <div
      v-if="iAmOrganizationRoot"
      class="offer__filter"
    >
      <div class="filter-top">
        <div class="filter-top__left-group">
          <div
            class="filter-top__element filter-button"
            @click="createOffer"
          >
            <IconPlus />
            <span class="filter-top__element__text office__text-create">{{ $t('vacancies.add_new') }}</span>
          </div>
        </div>

        <div class="filter-top__right-group">
          <div
            v-if="!isDraftFilters && !isEdit"
            class="filter-top__element filter-button"
            @click="$emit('edit')"
          >
            <IconPanEdit />
            <span class="filter-top__element__element-text office__text-management">
              {{ $t('vacancies.controls.title') }}
            </span>
          </div>

          <div
            v-if="!isDraftFilters && isEdit"
            class="filter-top__element filter-button"
          >
            <span
              class="filter-top__element__element-text"
              @click="$emit('edit')"
            >
              {{ $t('vacancies.controls.ok') }}
            </span>
            <span
              v-if="!isDraftFilters && selectedOffer && selectedOffer.length"
              class="filter-top__element__element-text filter-top__element__element-text_warm"
              @click="$emit('deactivate', isActiveFilters)"
            >
              {{ isActiveFilters ? $t('vacancies.controls.deactivate') : $t('vacancies.controls.activate') }}
            </span>
          </div>

          <component
            :is="layout.label"
            v-for="layout in layouts"
            :key="layout.value"
            class="filter-top__element__icon"
            :class="layout.class"
            :isActive="currentLayout === layout.value"
            @click="$emit('changeLayout', layout.value)"
          />
        </div>
      </div>

      <div class="filter-bottom">
        <div class="filter-top__left-group">
          <span
            v-for="item in statusGroups"
            :key="item.value"
            class="filter-top__element__text"
            :class="{
              'filter-top__element__text_active': filters.statusGroup === item.value,
              'filter-top__element__margin_right': item.hasMargin
            }"
            @click="changeStatus(item.value)"
          >{{ item.label }} ({{ item.count }})</span> <!--TODO РАСЧИТЫВАТЬ ДИНАМИЧЕСКИЙ-->
        </div>
      </div>
    </div>
    <v-card>
      <v-col
        cols="12"
        :sm="12"
        :md="12"
      >
        <WBSearchQueryFilter
          ref="searchQueryFilterRef"
          :remote-value="''"
          :label="'Поиск вакансии по названию или названию профессии'"
          @onSearchQueryFilterChange="onSearchQueryFilterChange"
          @onSearchQueryFilterCleared="onSearchQueryFilterCleared"
        />
      </v-col>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="warning"
          :disabled="!searchEnabled"
          @click="clearFilters"
        >
          очистить
        </v-btn>
        <v-btn
          rounded
          color="success"
          outlined
          :disabled="!searchEnabled"
          @click="applyFilters"
        >
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import IconTable from '@/components/common/icons/IconTable'
import IconGrid from '@/components/common/icons/IconGrid'
import { modalName } from '@/views/PageOfferList'
import WBSearchQueryFilter from '@/components/addressSearch/search-query-filter'


export default {
  name: 'OfferFilterBarCenter',
  components: { IconGrid, IconTable, IconPanEdit, IconPlus, WBSearchQueryFilter },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    pageLoading: {
      type: Boolean,
      required: false,
    },
    isScroll: {
      type: Boolean,
      required: false,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    currentLayout: {
      type: String,
      required: true,
    },
    selectedOffer: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      vacancyListFilterSet: {
        searchQuery: ''
      },
      modalName,
      statusGroups: [
        { label: this.$t('vacancies.filters.active'), value: 'active', count: '0' },
        { label: this.$t('vacancies.filters.inactive'), value: 'inactive', count: '0' },
        { label: this.$t('vacancies.filters.editing'), value: 'draft', count: '0' },
      ],
      layouts: [
        { label: IconGrid, value: 'grid', class: 'office__icon__grid' },
        { label: IconTable, value: 'table', class: 'office__icon__table' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      tabsCountData: 'vacancy/getTabsCountData'
    }),
    searchEnabled () {
      const { searchQuery } = this.vacancyListFilterSet
      return !this.pageLoading && !!searchQuery && searchQuery.trim() !== ''
    },
    isActiveFilters () {
      return this.filters.statusGroup && this.filters.statusGroup === 'active'
    },
    isDraftFilters () {
      return this.filters.statusGroup && this.filters.statusGroup === 'draft'
    }
  },
  watch: {
    tabsCountData (data) {
      this.statusGroups[0].count = data.activeCount
      this.statusGroups[1].count = data.inactiveCount
      this.statusGroups[2].count = data.draftCount
    }
  },
  methods: {
    changeStatus (statusGroup) {
      if (this.pageLoading) {
        return
      }
      const filters = { statusGroup }
      if (this.vacancyListFilterSet.searchQuery.length > 3) {
        filters.searchQuery = this.vacancyListFilterSet.searchQuery
      }
      this.$emit('changeStatus', filters)
    },
    async clearFilters () {
      if (this.pageLoading) {
        return
      }
      const searchQueryFilterRef = this.$refs.searchQueryFilterRef
      if (searchQueryFilterRef) {
        await searchQueryFilterRef.onClearSearch()
      }
    },
    applyFilters () {
      if (this.pageLoading) {
        return
      }
      const filters = { statusGroup: this.filters.statusGroup }
      if (this.vacancyListFilterSet.searchQuery.length > 3) {
        filters.searchQuery = this.vacancyListFilterSet.searchQuery
      }
      this.$emit('changeStatus', filters)
    },
    createOffer () {
      if (this.pageLoading) {
        return
      }
      this.$emit('createOffer')
    },
    onSearchQueryFilterChange (data) {
      this.AppLogger.info(data, 'onSearchQueryFilterChange')
      this.vacancyListFilterSet.searchQuery = data
    },
    onSearchQueryFilterCleared () {
      this.vacancyListFilterSet.searchQuery = ''
      this.applyFilters()
    },
  }
}
</script>

<style lang="sass" scoped>
  .office
    &__filter
      display: flex
      flex-direction: column

    &__icon__grid
      margin-left: 50px
      margin-right: 20px
      cursor: pointer
    &__icon__table
      cursor: pointer

    &__text-management
      @extend %button-text
      color: rgba(0, 0, 0, 0.61)
      margin-left: 8px

  .filter-bottom
    margin-top: 20px

</style>
