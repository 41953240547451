<template>
  <AppModalMiddle>
    <ModalHeader
      v-if="contentEnabled"
      @close="$emit('close')"
    >
      {{ modalTitle }}
    </ModalHeader>

    <!-- header with steps -->
    <VacanciesModalFormHeader
      v-if="contentEnabled"
      :active-step="activeStep"
      :active-title="activeTitle"
      :steps="steps"
      @changeStep="onChangeStep"
    />

    <div
      v-if="contentEnabled"
      class="vacancies-modal-form"
    >
      <!-- step main -->
      <VacanciesModalFormStepMain
        v-if="activeStep === 0 && professions.length"
        :vacancie-card-data="vacancieCardData"
        :get-params-flag="getParamsFlagMain"
        :professions="professions"
        @onFormValid="onValidFrom"
        @onSaveVacancyDetail="onSaveVacancyDetail"
      />

      <!-- pay and region -->
      <VacanciesModalFormStepPayRegion
        v-if="activeStep === 1 && professions.length && (vacancieCardData.id || currentVacancyData.id)"
        :vacancie-card-data="vacancieCardData.id ? vacancieCardData : currentVacancyData"
        :regions="regionList"
        :professions="professions"
        :get-params-flag.sync="getParamsFlagRegion"
        :loading="isLoading"
        @onFormValid="onValidFrom"
        @onToggleActions="onToggleActions"
        @onSaveVacancyRegionSettings="onSaveVacancyRegionSettings"
      />

      <!-- questions -->
      <VacanciesModalFormStepQuestions
        v-show="activeStep === 2"
        ref="step3"
        :vacancie-card-data="vacancieCardData"
        :vacancie-id="vacancyId"
        @onFormValid="onValidFrom"
        @onDeleteQuestion="onDeleteQuestion"
      />


      <!-- information -->
      <VacanciesModalFormStepInformation
        v-show="activeStep === 3"
        ref="step4"
        :vacancie-card-data="vacancieCardData"
        :vacancie-id="vacancyId"
        @onFormValid="onValidFrom"
      />


      <!-- save buttons -->
      <div
        v-if="showAction"
        class="vacancies-modal-form__controls-invisible"
      />
      <div
        v-if="showAction"
        class="vacancies-modal-form__controls"
      >
        <div
          v-if="false"
          class="vacancies-modal-form__controls__save"
        >
          {{ $t('common.save') }}
        </div>
        <AppLoader v-if="isLoading" />
        <v-btn
          v-else
          class="success"
          rounded
          :disabled="!saveButtonActive"
          @click.native="createOrUpdate()"
        >
          {{ 'Сохранить и продолжить' }}
        </v-btn>
      </div>
    </div>
    <WbdCarouselSlider
      v-else
      @onWBDCarouselClose="onWBDCarouselClose"
    />
  </AppModalMiddle>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import ModalHeader from '@/components/common/modal/ModalHeader'
import VacanciesModalFormHeader from './VacanciesModalFormHeader'
import VacanciesModalFormStepMain from './VacanciesModalFormStepMain'
import VacanciesModalFormStepPayRegion from './VacanciesModalFormStepPayRegion'
import VacanciesModalFormStepQuestions from './VacanciesModalFormStepQuestions'
import VacanciesModalFormStepInformation from './VacanciesModalFormStepInformation'
import AppLoader from '@/components/AppLoader'
import WbdCarouselSlider from '@/components/carousel/slider'

export default {
  name: 'VacanciesModalForm',
  components: {
    WbdCarouselSlider,
    ModalHeader,
    AppModalMiddle,
    VacanciesModalFormHeader,
    VacanciesModalFormStepMain,
    VacanciesModalFormStepPayRegion,
    VacanciesModalFormStepInformation,
    VacanciesModalFormStepQuestions,
    AppLoader,
  },
  props: {
    vacancieCardData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      contentEnabled: false,
      deletedQuestionList: new Set(),
      questionLoading: false,
      loaderData: {
        VACANCY_FORM: 'VACANCY_FORM',
        VACANCY_LIST: 'VACANCY_LIST',
      },
      currentLoader: null,
      vacancyId: (this.vacancieCardData && this.vacancieCardData.id) || '',
      createdVacancyData: null,
      getParamsFlag: false,
      getParamsFlagMain: false,
      getParamsFlagRegion: false,
      apiParams: {},
      activeStep: 0,
      validForm: false,
      showAction: true,
      stepTitles: {
        new: [
          this.$t('vacancies.form.new.step_1_title'),
          this.$t('vacancies.form.new.step_2_title'),
          this.$t('vacancies.form.new.step_3_title'),
          'Важная информация'
        ],
        edit: [
          this.$t('vacancies.form.edit.step_1_title'),
          this.$t('vacancies.form.edit.step_2_title'),
          this.$t('vacancies.form.edit.step_3_title'),
          'Важная информация'
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      professions: 'vacancy/getVacancyProfession',
      regionList: 'vacancy/getRegionList',
      currentVacancyData: 'vacancy/getVacancieCardData'
    }),
    isLoading () {
      return this.currentLoader === this.loaderData.VACANCY_FORM
    },
    steps () {
      return [1, 2, 3, 4]
    },
    saveButtonActive () {
      return this.validForm && this.currentLoader !== this.loaderData.VACANCY_FORM
    },
    isEditing () {
      return this.vacancieCardData.id
    },
    modalTitle () {
      return this.$t(this.isEditing ? 'vacancies.edit_vacancie' : 'vacancies.create_vacancie')
    },
    submitTitle () {
      return this.$t(this.isEditing ? 'vacancies.save_changes' : 'vacancies.add_new')
    },
    activeTitle () {
      return this.stepTitles[this.isEditing ? 'edit' : 'new'][this.activeStep]
    },
    getMe () {
      return this.$store.state.users.info
    },
    getMySettings () {
      const user = this.getMe
      if (!user) {
        return null
      }
      return user.settingList
    },
    vacancyOnBoardingDisabled () {
      const settingList = this.getMySettings
      if (!settingList || !settingList.length) {
        return false
      }
      const curr = this.getMySettings.find((item) => item.alias === 'hide-vacancy-on-boarding')
      return !!curr && !!curr.id && !curr.isActive
    },
  },
  created () {
    this.fetchVacancyProfession()
    this.fetchRegionList()
    if (this.vacancyOnBoardingDisabled) {
      this.contentEnabled = true
    }
  },
  methods: {
    ...mapActions({
      addQuestionById: 'vacancy/addQuestionById',
      updateQuestionList: 'vacancy/updateQuestionList',
      removeQuestionList: 'vacancy/removeQuestionList',
      updateVacancy: 'vacancy/updateVacancy',
      createVacancy: 'vacancy/createVacancy',
      updateRegion: 'vacancy/updateRegion',
      createRegion: 'vacancy/createRegion',
      fetchVacancyList: 'vacancy/fetchVacancyList',
      fetchVacancieById: 'vacancy/fetchVacancieById',
      fetchVacancyProfession: 'vacancy/fetchVacancyProfession',
      fetchRegionList: 'vacancy/fetchRegionList'
    }),
    onChangeStep (step) {
      if (this.vacancieCardData && this.vacancieCardData.id) {
        this.activeStep = step
      }
    },
    onValidFrom (state) {
      this.validForm = state
    },
    onToggleActions (state) {
      this.showAction = state
    },
    onDeleteQuestion (id) {
      this.deletedQuestionList.add(id)
    },
    async createOrUpdate () {
      if (this.activeStep === 0) {
        this.getParamsFlagMain = true
        this.updateStep()
        return true
      }

      if (this.activeStep === 1) {
        this.getParamsFlagRegion = true
        this.updateStep()
        return true
      }

      if (this.activeStep === 2) {
        this.updateQuestionModal()
        return true
      }

      if (this.activeStep === 3) {
        this.manageVacancyInformation()
      }
    },
    updateStep () {
      setTimeout(() => {
        if (this.activeStep === 0) {
          this.getParamsFlagMain = false
        }
        if (this.activeStep === 1) {
          this.getParamsFlagRegion = false
        }
      }, 1000)
    },
    onSaveVacancyDetail (params) {
      this.currentLoader = this.loaderData.VACANCY_FORM
      if (this.isEditing) {
        this.updateVacancy(params).then((res) => {
          this.vacancyId = res.data.id
          this.fetchVacancyList()
          this.fetchVacancieById({ id: res.data.id }).then(() => {
            this.activeStep++
            this.currentLoader = null
          })
        }).catch(() => {
          this.currentLoader = null
        })
      } else {
        this.createVacancy(params).then((res) => {
          this.vacancyId = res.data.id
          this.createdVacancyData = {
            ...params,
            id: res.data.id
          }
          // step 1
          this.$emit('changeStatus', 'draft')
          this.fetchVacancieById({ id: res.data.id }).then(() => {
            this.activeStep++
            this.currentLoader = null
          })
        }).catch(() => {
          this.currentLoader = null
        })
      }
    },
    manageVacancyInformation () {
      if (this.activeStep === 3) {
        this.currentLoader = this.loaderData.VACANCY_FORM
        const vacancyData = this.vacancieCardData && this.vacancieCardData.id
          ? this.vacancieCardData : this.createdVacancyData
        const data = {
          id: vacancyData.id,
          title: vacancyData.title,
          externalTitle: vacancyData.externalTitle,
          isPrivate: vacancyData.isPrivate,
          requiredMedicalBook: vacancyData.requiredMedicalBook,
          laborerHasMaterialResponsibility: vacancyData.laborerHasMaterialResponsibility,
          profession: { id: vacancyData.profession.id },
          competenceList: vacancyData.competenceList.map(({ id }) => ({ id })),
          message: this.$refs.step4.currentDescription
        }

        this.updateVacancy(data).then((res) => {
          if (this.vacancieCardData && this.vacancieCardData.id) {
            this.fetchVacancieById({ id: res.data.id }).then(() => {
              this.$emit('close')
              this.currentLoader = null
            })
          } else {
            this.fetchVacancyList().then(() => {
              this.$emit('close')
              this.currentLoader = null
            })
          }
        }).catch(() => {
          this.currentLoader = null
        })
      }
    },
    updateQuestionModal () {
      this.currentLoader = this.loaderData.VACANCY_FORM
      if (this.activeStep === 2) {
        // TODO добавить в общий запорос и закрыть попап только при завер
        const newQuestionList = this.$refs.step3.getQuestionList().filter((item) => item.description && !item.id)
        const editedQuestionList = this.$refs.step3.getQuestionList().filter(
          (item) => item.description && item.id && item.id.trim().length
        )

        if (editedQuestionList.length || newQuestionList.length || this.deletedQuestionList.size) {
          if (editedQuestionList.length) {
            this.currentLoader = this.loaderData.VACANCY_FORM

            this.questionLoading = true
            this.updateQuestionList({
              questionList: editedQuestionList
            }).then((res) => {
              if (!newQuestionList.length && !this.deletedQuestionList.size) {
                this.questionLoading = false
                this.currentLoader = null
                this.fetchVacancieById({ id: this.vacancyId })
                this.activeStep++
              }
            })
          }
          if (newQuestionList.length) {
            this.currentLoader = this.loaderData.VACANCY_FORM

            this.questionLoading = true
            this.addQuestionById({
              vacancy: {
                id: this.vacancyId
              },
              questionList: newQuestionList
            }).then((res) => {
              if (!this.deletedQuestionList.size) {
                this.questionLoading = false
                this.currentLoader = null
                this.fetchVacancieById({ id: this.vacancyId })
                this.activeStep++
              }
            })
          }
          if (this.deletedQuestionList.size) {
            this.currentLoader = this.loaderData.VACANCY_FORM

            this.removeQuestionList({
              questionIdList: Array.from(this.deletedQuestionList)
            }).then((res) => {
              this.questionLoading = false
              this.currentLoader = null
              this.fetchVacancieById({ id: this.vacancyId })
              this.activeStep++
            })
          }
        } else {
          this.currentLoader = null
          this.activeStep++
        }
      } else {
        this.currentLoader = null
      }
    },
    onSaveVacancyRegionSettings (params) {
      this.currentLoader = this.loaderData.VACANCY_FORM
      if (this.isEditing && this.vacancieCardData.regionSetting[0]) {
        const editVacancyProfessionRegionSettingDtoList = []
        const vacancyProfessionRegionSettingList = []
        for (const item of params) {
          if (item.id) {
            // editVacancyProfessionRegionSettingDtoList.push(item)
          } else {
            vacancyProfessionRegionSettingList.push(item)
          }
        }
        this.updateRegion({
          // id: this.vacancieCardData.regionSetting[0].id,
          // minPay: params.minPay,
          // maxPay: params.maxPay,
          // hasFixedPay: params.hasFixedPay,
          // payPerHour: params.payPerHour,
          // selfEmployerPayPerHour: +params.selfEmployerPayPerHour,
          // minSelfEmployerPay: +params.minSelfEmployerPay,
          // maxSelfEmployerPay: +params.maxSelfEmployerPay,
          // professionRegionSetting: params.professionRegionSetting,
          vacancy: { id: this.vacancieCardData.id },
          editVacancyProfessionRegionSettingDtoList,
          vacancyProfessionRegionSettingList,
        }).then((res) => {
          this.activeStep++
          this.currentLoader = null
          this.$store.dispatch('vacancy/fetchVacancieById', { id: this.vacancieCardData.id })
        }).catch(() => {
          this.currentLoader = null
        })
      } else {
        this.createRegion({
          vacancy: { id: this.vacancyId },
          vacancyProfessionRegionSettingList: params
        }).then((res) => {
          this.activeStep++
          this.currentLoader = null
          this.$emit('changeStatus', 'active')
          this.fetchVacancyList()
        }).catch(() => {
          this.currentLoader = null
        })
      }
    },
    onWBDCarouselClose (disable) {
      this.contentEnabled = true
      // if (disable) {}
    }
  }
}
</script>
<style lang="sass">
.vacancies-modal-form
  position: relative
  min-height: 600px

  &__controls-invisible
    width: 10px
    height: 80px

  &__controls
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100%
    position: absolute
    bottom: 0
    padding: 30px 0 0 0
    border-top: 1px solid rgba(0, 0, 0, 0.1)

    &__save
      @include fontStyle(800, 18px)
      margin-right: 20px
      color: #000
      opacity: .7
</style>
