<template>
  <!--  :height="getSortedRegionList.length > 2 ? '400px': undefined"-->

  <v-simple-table
    height="400px"
    dense
    group-by="title"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Населенный пункт
          </th>
          <th class="text-left">
            Физлицо на руки
          </th>
          <th class="text-left">
            Физлицо Цена услуги
          </th>
          <th class="text-left">
            Самозанятый На руки
          </th>
          <th class="text-left">
            Самозанятый Цена услуги
          </th>
          <th class="text-left" />
        </tr>
      </thead>
      <tbody>
        <VacancyProfessionRegionSettingItem
          v-for="vacancyRegionSetting in getSortedRegionList"
          :key="vacancyRegionSetting.id"
          :vacancy-region-setting="vacancyRegionSetting"
          @onDeleteEntry="onDeleteEntry"
        />
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import VacancyProfessionRegionSettingItem from '@/components/vacancies/VacancyProfessionRegionSettingItem'

export default {
  name: 'VacancyProfessionRegionSettingList',
  components: { VacancyProfessionRegionSettingItem },
  props: {
    isPrivate: {
      type: Boolean,
    },
    regionsList: {
      type: Array
    },
  },
  data () {
    return {
    }
  },
  computed: {
    getSortedRegionList () {
      // return this.regionsList
      const self = this
      return self.regionsList.map((item) => ({
        ...item,
        title: item.regionSetting[0].region.title
      })).sort((a, b) => {
        if (a.regionSetting[0].region.title > b.regionSetting[0].region.title) {
          return 1
        }
        if (a.regionSetting[0].region.title < b.regionSetting[0].region.title) {
          return -1
        }
        return 0
      })
    }
  },
  created () {
    // TODO check
  },
  methods: {
    onDeleteEntry (entry) {
      this.$emit('onDeleteEntry', entry)
    }
  }
}
</script>
<style lang="sass">
.panel-min-height
  min-height: 40px
.error-input-wrapper
  color: red
  font-size: 0.6em
  position: absolute
  bottom: -9px
  left: 10px
.on-field-error
  border-color: red !important
.text-description-title
  font-style: normal
  font-weight: 800
  font-size: 14px
  line-height: 17px
  color: #000000
  padding-left: 60px
.text-description
  font-size: 14px
  line-height: 17px
  color: #000000
  opacity: 0.8
.modal-row
  margin: 1px 0 0 !important
  padding: 0!important
.modal-row-separator
  margin: 4px auto !important
.modal-row-item
  min-width: 100px
  padding-left: 15px
.payment-label-item
  margin-bottom: 4px
.row-item-relative
  position: relative
.vacancies-modal-from-step-main
  &__field-right
    display: flex
    justify-content: space-between
    align-items: center
    margin: 20px 0
    position: relative

    &__title-require
      min-width: 120px
      position: absolute
      top: -61px
      @include fontStyle(500, 13px)
      opacity: .3

    &__title
      @include fontStyle(500, 14px)

    &__title-question
      @include fontStyle(800, 16px)
      opacity: .3

    &__input
      width: 70px
      margin: 0 15px

    &__description
      width: 65px
      @include fontStyle(500, 12px)
      opacity: .2

</style>
