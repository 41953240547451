<template>
  <div class="vacancies-modal-from-step-main">
    <div
      class="text-description"
      style="margin-bottom: 24px"
    >
      Укажите минимальные ставки по выбранной вакансии. Если вы работаете в разных регионах,
      то выберете несколько и укажите ставку для каждого. Если вашего региона нет, обратитесь в поддержку.
      Укажите ставки, которые вы будете платить и для физлиц и для самозанятых. К ставке физлиц прибавляются налоги
    </div>

    <div v-if="vacancieCardData && vacancieCardData.id">
      <v-card-actions>
        <p
          v-if="canAddRegionSettings === 1"
          style="
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; margin-top: 8px; margin-bottom: 8px"
        >
          <IconPlus
            :width="32"
            :height="32"
            :fill="!canAddRegionSettings ? 'gray' : undefined"
            class="vacancies-modal-from-step-main__plus"
            @click.native="increaseRegionSetting"
          />
        </p>
        <v-btn
          v-if="canAddRegionSettings"
          text
          class="success"
          rounded
          small
          @click.native="increaseRegionSetting"
        >
          <v-icon class="white--text">
            mdi-plus
          </v-icon>
          <span style="text-transform: none">{{ `  Добавить населенный пункт ` }}</span>
        </v-btn>
        <v-spacer v-if="canAddRegionSettings" />
        <v-btn
          v-if="currentRegionRate"
          color="error"
          rounded
          small
          outlined
          text
          :disabled="!isFormValid || loading"
          @click="onDeleteEntry"
        >
          {{ 'Отменить' }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="currentRegionRate"
          class="success"
          rounded
          small
          :disabled="!currentRegionRate.isValid || loading"
          @click="addEntry"
        >
          <span style="text-transform: none"> {{ 'Добавить ставку' }}</span>
        </v-btn>
      </v-card-actions>
      <VacancyProfessionRegionSettingForm
        v-if="currentRegionRate"
        :vacancieCardData="vacancieCardData"
        :form.sync="currentRegionRate"
        :isPrivate="vacancieCardData.isPrivate"
        :regionsList="allowedRegionList"
        :professions="allowedProfessionList"
        :closable="vacancyRegionSettingList.length > 1"
        @onRegionSettingValid="onPrevRegionIsValid"
        @onDeleteEntry="onDeleteEntry"
      />
      <VacancyProfessionRegionSettingList
        v-if="vacancyRegionSettingList.filter(item => !!item.isValid).length"
        :regionsList="vacancyRegionSettingList.filter(item => !!item.isValid)"
        @onDeleteEntry="onDeleteEntry"
      />
    </div>
  </div>
</template>
<script>
import VacancyProfessionRegionSettingForm from '@/components/vacancies/VacancyProfessionRegionSettingForm'
import VacancyProfessionRegionSettingList from '@/components/vacancies/VacancyProfessionRegionSettingList'
import taxMixin from '@/mixins/taxMixin.vue'
import IconPlus from '@/components/common/icons/IconPlus'


export default {
  name: 'VacanciesModalFormStepPayments',
  components: {
    IconPlus,
    VacancyProfessionRegionSettingList,
    VacancyProfessionRegionSettingForm,
  },
  mixins: [taxMixin],
  props: {
    vacancieCardData: {
      type: Object,
      default () {
        return {
          regionSetting: [{
            hasFixedPay: false,
            hourlyPay: '',
            maxPay: '',
            region: ''
          }]
        }
      }
    },
    getParamsFlag: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    regions: {
      type: Array,
      default () {
        return []
      }
    },
    professions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data (vm) {
    return {
      region: '',
      vacancyRegionSettingList: [
      ],
      currentRegionRate: undefined,
      form: {
        hourlyPay: this.vacancieCardData.hourlyPay || '',
        selfEmployerHourlyPay: this.vacancieCardData.selfEmployerHourlyPay || '',
        regionSetting: [
          {
            token: 'token-1',
            hasFixedPay: this.hasFixedPay || true,
            maxPay: '',
            maxSelfEmployerPay: '',
            region: {
              title: ''
            }
          },
          {
            token: 'token-2',
            hasFixedPay: this.hasFixedPay || true,
            maxPay: '',
            maxSelfEmployerPay: '',
            region: {
              title: ''
            }
          }
        ],
      }
    }
  },
  computed: {
    allowedProfessionList () {
      if (!this.vacancyRegionSettingList.length) {
        return this.professions
      }
      const selectedProfessionIdList = this.vacancyRegionSettingList.filter(
        (j) => j.isValid
      ).map((item) => item.professionRegionSetting.id)

      return this.professions.filter((prof) => !selectedProfessionIdList.includes(prof.regionSetting.id))
    },
    allowedRegionList () {
      if (!this.vacancyRegionSettingList.length) {
        return this.regionsList
      }

      const selectedRegionSettingIdList = this.vacancyRegionSettingList.filter(
        (j) => j.isValid || !!j.id
      ).map((item) => item.professionRegionSetting.region.id)

      return this.regionsList.filter((region) => !selectedRegionSettingIdList.includes(region.id))
    },
    canAddRegionSettings () {
      this.isFormValid()
      // return this.vacancyRegionSettingList.filter((item) => !item.isValid).length === 0
      return !this.currentRegionRate
    },
    regionsList () {
      return this.regions
    }
  },
  watch: {
    getParamsFlag (flag) {
      this.saveRegionSettings(flag)
    },
    'currentRegionRate.token' (val) {
      this.$emit('onToggleActions', !val)
    }
  },
  created () {
    if (this.vacancieCardData.regionSetting) {
      for (const regionSetting of this.vacancieCardData.regionSetting) {
        this.vacancyRegionSettingList.push(
          {
            token: `${regionSetting.id}`,
            id: regionSetting.id,
            hourlyPay: regionSetting.payPerHour,
            selfEmployerHourlyPay: regionSetting.minSelfEmployerPay,
            professionRegionSetting: regionSetting,
            isValid: true,
            regionSetting: [regionSetting],
          }
        )
      }
    }

    // this.isFormValid()
  },
  mounted () {

  },
  methods: {
    isFormValid () {
      const isValid = this.vacancyRegionSettingList.length > 0 &&
        this.vacancyRegionSettingList.filter((item) => !item.isValid).length === 0

      this.$emit('onFormValid', isValid)
    },
    increaseRegionSetting () {
      if (!this.canAddRegionSettings || !this.allowedRegionList.length) {
        return
      }
      this.currentRegionRate = {
        token: `token--${this.vacancyRegionSettingList.length + 2}`,
        id: null,
        hourlyPay: '',
        selfEmployerHourlyPay: '',
        professionRegionSetting: null,
        isValid: false,
        regionSetting: [
          {
            hasFixedPay: this.hasFixedPay || true,
            maxPay: '',
            maxSelfEmployerPay: '',
            region: {
              title: ''
            }
          },
        ],
      }
      // this.vacancyRegionSettingList.unshift(
      //   {
      //     token: `token--${this.vacancyRegionSettingList.length + 2}`,
      //     id: null,
      //     hourlyPay: this.vacancieCardData.hourlyPay || '',
      //     selfEmployerHourlyPay: this.vacancieCardData.selfEmployerHourlyPay || '',
      //     professionRegionSetting: null,
      //     isValid: false,
      //     regionSetting: [
      //       {
      //         hasFixedPay: this.hasFixedPay || true,
      //         maxPay: '',
      //         maxSelfEmployerPay: '',
      //         region: {
      //           title: ''
      //         }
      //       },
      //     ],
      //   }
      // )
    },
    onPrevRegionIsValid (value) {
    },
    onDeleteEntry (value) {
      this.vacancyRegionSettingList = this.vacancyRegionSettingList.filter((item) => item.token !== value.token)
      if (value.id) {
        this.$store.dispatch('vacancy/deleteRegion', { id: value.id })
      } else {
        this.currentRegionRate = undefined
      }
    },
    addEntry () {
      if (this.currentRegionRate && this.currentRegionRate.isValid) {
        this.vacancyRegionSettingList.unshift(
          {
            ...this.currentRegionRate
          }
        )

        this.currentRegionRate = undefined
      }
    },
    saveRegionSettings (flag) {
      const list = this.vacancyRegionSettingList.map((form) => {
        const { locality, region } = form.professionRegionSetting
        let regionId

        const params = {
          id: form.id,
          hasFixedPay: form.regionSetting[0].hasFixedPay,
          minPay: +form.hourlyPay,
          maxPay: +form.regionSetting[0].maxPay,
          payPerHour: +form.hourlyPay,
          selfEmployerPayPerHour: +form.selfEmployerHourlyPay,
          minSelfEmployerPay: +form.selfEmployerHourlyPay,
          maxSelfEmployerPay: +form.regionSetting[0].maxSelfEmployerPay,
          professionDto: { id: this.vacancieCardData.profession.id },
          regionDto: null,
        }
        if (locality && locality.id) {
          params.locality = { id: locality.id }
          regionId = region.id
        } else {
          regionId = region.id
        }

        params.regionDto = { id: regionId }

        return params
      })

      if (flag) {
        this.$emit('onSaveVacancyRegionSettings', list)
      }
    }
  }
}
</script>
<style lang="sass">
.error-input-wrapper
  color: red
  font-size: 0.6em
  position: absolute
  bottom: -9px
  left: 10px
.on-field-error
  border-color: red !important
.text-description-title
  font-style: normal
  font-weight: 800
  font-size: 14px
  line-height: 17px
  color: #000000
  padding-left: 60px
.text-description
  font-size: 14px
  line-height: 17px
  color: #000000
  opacity: 0.8
.modal-row
  margin: 1px 0 0 !important
  padding: 0!important
.modal-row-separator
  margin: 4px auto !important
.modal-row-item
  min-width: 100px
  padding-left: 15px
.payment-label-item
  margin-bottom: 4px
.row-item-relative
  position: relative
.vacancies-modal-from-step-main
  &__field-right
    display: flex
    justify-content: space-between
    align-items: center
    margin: 20px 0
    position: relative

    &__title-require
      min-width: 120px
      position: absolute
      top: -61px
      @include fontStyle(500, 13px)
      opacity: .3

    &__title
      @include fontStyle(500, 14px)

    &__title-question
      @include fontStyle(800, 16px)
      opacity: .3

    &__input
      width: 70px
      margin: 0 15px

    &__description
      width: 65px
      @include fontStyle(500, 12px)
      opacity: .2

</style>
