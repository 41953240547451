var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-grid-item',{staticClass:"vacancies__grid__item",class:{
    'vacancies__grid__item_selected': _vm.isSelected
  },staticStyle:{"padding":"16px !important"},attrs:{"item":_vm.offer},on:{"click":_vm.clickToOffer}},[_c('div',{staticClass:"vacancies__grid__item__header"},[_c('IconEmployee'),_c('span',{staticClass:"vacancies__grid__item__header__title"},[_vm._v(" "+_vm._s(_vm.offer.title)+" ")])],1),_c('div',{staticClass:"vacancies__grid__item__period"},[_c('div',{staticClass:"vacancies__grid__item__region"},[_c('div',{staticClass:"vacancies__grid__item__period__profession__text"},[_c('span',{staticClass:"vacancies__grid__item__period__profession__text__title"},[_vm._v(" "+_vm._s(_vm.$t('vacancies.grid_card.profession'))+" ")]),_vm._v(": "),_c('span',[_vm._v(_vm._s(_vm.offer.profession.title))])])])]),_c('div',{staticClass:"vacancies__grid__item__info vacancies__grid__item__info_state_column"},[_c('div',{staticClass:"vacancies__grid__item__period__pay__text",class:{
        'vacancies__grid__item__period_check_not': !_vm.offer.requiredMedicalBook
      }},[(_vm.offer.requiredMedicalBook)?_c('img',{attrs:{"src":require("@/assets/icon-check.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('vacancies.docs.requiredMedicalBook'))+" ")]),_c('div',{staticClass:"vacancies__grid__item__period__pay__text",class:{
        'vacancies__grid__item__period_check_not': !_vm.offer.laborerHasMaterialResponsibility
      }},[(_vm.offer.laborerHasMaterialResponsibility)?_c('img',{attrs:{"src":require("@/assets/icon-check.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('vacancies.docs.laborerHasMaterialResponsibility'))+" ")]),_c('div',{staticClass:"vacancies__grid__item__period__pay__text",class:{
        'vacancies__grid__item__period_check_not': !_vm.offer.hasQuestionList
      }},[(_vm.offer.hasQuestionList)?_c('img',{attrs:{"src":require("@/assets/icon-check.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('vacancies.docs.hasQuestionList'))+" ")])]),_c('div',{staticClass:"vacancies__grid__item__info"},[_c('span',{staticClass:"vacancies__grid__item__period__simple__text"},[_c('span',{staticClass:"vacancies__grid__item__period__profession__text__title"},[_vm._v(" "+_vm._s(_vm.$t('vacancies.grid_card.region'))+" ")]),_vm._v(": "),_c('span',[_vm._v(_vm._s(_vm.regionTitle))])])]),_c('div',{staticClass:"vacancies__grid__item__info vacancies__grid__item__info_bottom_none"},[_c('div',{staticClass:"vacancies__grid-column"},[_c('div',{staticClass:"vacancies__grid__item__period__pay__text"},[_vm._v(" часовая ставка: ")]),_c('span',{staticClass:"vacancies__grid__item__bottom__price-full"},[_c('span',{staticClass:"self-employment-label"},[_vm._v("Физлицо: ")]),_vm._v(" "+_vm._s(_vm.priceOfHour)+" ")]),_c('span',{staticClass:"vacancies__grid__item__bottom__price-full"},[_c('span',{staticClass:"self-employment-label"},[_vm._v("Самозанятый: ")]),_vm._v(" "+_vm._s(_vm.selfEmployerPayPerHour)+" ")])]),(!_vm.hasFixedPay)?_c('div',{staticClass:"vacancies__grid-column"},[_c('div',{staticClass:"vacancies__grid__item__period__pay__text"},[_vm._v(" Макс. часовая ставка: ")]),_c('span',{staticClass:"vacancies__grid__item__bottom__price-full"},[_c('span',{staticClass:"self-employment-label"},[_vm._v("Физлицо: ")]),_vm._v(" "+_vm._s(_vm.maxPriceOfHour)+" ")]),_c('span',{staticClass:"vacancies__grid__item__bottom__price-full"},[_c('span',{staticClass:"self-employment-label"},[_vm._v("Самозанятый: ")]),_vm._v(" "+_vm._s(_vm.selfEmploymentMaxPay)+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }