<template>
  <div>
    <v-data-table
      v-model="selectedVacancyList"
      :items="offersOffice"
      :headers="headers"
      :loading="isShowLoad"
      :options.sync="options"
      :server-items-length="paginator || getOrgTotalVacancyNumber"
      :footer-props="footerProps"
      :show-select="isEdit"
      class="elevation-1"
      fixed-header
      loading-text="Идет загрузка"
      no-results-text="Список пуст"
      no-data-text="Список пуст"
      :hide-default-footer="!offersOffice.length"
      dense
    >
      <template
        v-if="!isEdit"
        v-slot:item.state="{ item }"
      >
        <v-icon color="success">
          mdi-account-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.supportNumber="{ item }">
        <v-btn
          link
          text
          color="primary"
          @click.stop="onRowClick(item)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-eye
          </v-icon>
          <span style="text-decoration: underline">{{ item.supportNumber }}</span>
        </v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment("DD/MM/YY HH:mm") }}
      </template>
      <template v-slot:item.laborerPayment="{ item }">
        <div style="min-width: 160px">
          <v-expansion-panels dense>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ставки {{ ` (${ item.laborerPaymentCounter})` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.laborerPayment }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-slot:item.selfEmployeePayment="{ item }">
        <div style="min-width: 160px">
          <v-expansion-panels dense>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ставки {{ ` (${ item.selfEmployeePaymentCounter})` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.selfEmployeePayment }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-slot:item.regionTitle="{ item }">
        <div style="min-width: 180px">
          <v-expansion-panels dense>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Регионы {{ ` (${ item.regionTitleCounter})` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.regionTitle }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn
          text
          @click="$emit('rowClicked', item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!--    TODO REMOVE-->
    <app-table
      v-if="isEdit === 230000"
      :keys="keys"
      :array="offersOffice"
      :isEdit="isEdit"
      :isShowLoad="isShowLoad"
      :checked-item="offerChecked"
      class="vacancies__table"
      @selectCombobox="(item) => { this.$emit('selectCombobox', item) }"
      @click="(item) => { this.$emit('click', item) }"
      @selectAll="(val) => {this.$emit('selectAll', val)}"
    >
      <template #employers="{item: vacancie}">
        <span
          v-show="!isEdit"
          class="vacancies__table__employee"
        >
          <IconEmployee style="vertical-align: middle;" />
        </span>
      </template>

      <template #title="{item: vacancie}">
        <span class="vacancies__table__vacancy">
          {{ vacancie.title }}
        </span>
      </template>

      <template #profession.title="{item: vacancie}">
        <span class="vacancies__table__profession">
          {{ vacancie.profession.title }}
        </span>
      </template>
      <template #profession.selfEmployeePayment="{item: vacancie}">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item,i) in 5"
            :key="i"
          >
            <v-expansion-panel-header>
              Item
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adi
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template #regionObject="{item: vacancie}">
        <span class="vacancies__table__region">
          {{ vacancie.regionSetting && vacancie.regionSetting[0] &&
            vacancie.regionSetting[0].region && vacancie.regionSetting[0].region.title
          }}
        </span>
      </template>

      <template #payPerHour="{item: vacancie}">
        <div class="vacancies__table__pay">
          {{ vacancie.regionSetting && vacancie.regionSetting[0] && vacancie.regionSetting[0].payPerHour }} ₽
        </div>
      </template>
      <template #payMax="{item: vacancie}">
        <div class="vacancies__table__pay">
          {{ maxPriceOfHour(vacancie) }}
        </div>
      </template>
      <template #selfEmployerPayPerHour="{item: vacancie}">
        <div class="vacancies__table__pay">
          {{ selfEmployerPayPerHour(vacancie) }} ₽
        </div>
      </template>

      <template #maxSelfEmployerPay="{item: vacancie}">
        <div class="vacancies__table__pay">
          {{ selfEmploymentMaxPay(vacancie) }}
        </div>
      </template>
    </app-table>
    <!--    TODO REMOVE-->
  </div>
</template>

<script>
import AppTable from '@/components/common/AppTable'
import IconEmployee from '@/components/common/icons/IconEmployee'
import ListMixin from '@/mixins/list-mixin.vue'

export default {
  name: 'VacanciesTable',
  components: { IconEmployee, AppTable },
  mixins: [ListMixin],
  props: {
    offersOffice: {
      type: Array,
      required: true
    },
    isShowLoad: {
      type: Boolean,
      default: true
    },
    keys: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    offerChecked: {
      type: Array,
      required: true
    },
    paginator: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selectedVacancyList: [],
      options: {
      },
      headers: [
        {
          text: 'Номер вакансии',
          align: 'left',
          sortable: false,
          value: 'supportNumber',
        },
        // {
        //   text: 'Дата',
        //   align: 'left',
        //   sortable: false,
        //   value: 'createdAt',
        // },
        {
          text: 'Внутреннее название',
          align: 'left',
          sortable: false,
          value: 'title',
        },
        {
          text: 'Внешнее название',
          align: 'left',
          sortable: false,
          value: 'externalTitle',
        },
        {
          text: this.$t('vacancies.table.profession'),
          align: 'left',
          sortable: false,
          value: 'profession.title',
        },
        {
          text: 'Регионы',
          align: 'left',
          sortable: false,
          value: 'regionTitle',
        },
        {
          text: 'Ставка в час ФИЗ',
          align: 'left',
          sortable: false,
          value: 'laborerPayment',
        },
        {
          text: 'Ставка в час СМЗ',
          align: 'left',
          sortable: false,
          value: 'selfEmployeePayment',
        },
        {
          text: 'Для своих',
          align: 'left',
          sortable: false,
          value: 'forRemoteStaff',
        },
      ],
    }
  },
  computed: {

  },
  watch: {
    options: {
      handler () {
        const filter = {
          ...this.options
        }
        this.$emit('onOptionChange', filter)
      },
      deep: true,
    },
    selectedVacancyList: {
      handler () {
        this.onItemSelected()
      },
      deep: true,
    },
  },
  created () {
    this.options = {
      page: 1,
      itemsPerPage: this.footerProps.itemsPerPageOptions[0],
    }
  },

  methods: {
    regionSettingExists (vacancie) {
      return vacancie && vacancie.regionSetting && vacancie.regionSetting.length
    },
    onItemSelected () {
      this.$emit('onItemSelected', this.selectedVacancyList.map((vacancy) => vacancy.id))
    },
    onRowClick (data) {
      this.$emit('click', data)
    },
    getCurrentRegion (vacancie) {
      if (!this.regionSettingExists(vacancie)) {
        return null
      }
      return vacancie.regionSetting[0]
    },
    selfEmploymentMaxPay (vacancie) {
      if (!this.getCurrentRegion(vacancie)) {
        return '-'
      }
      const pay = this.getCurrentRegion(vacancie).maxSelfEmployerPay
      return pay ? `${pay} ₽` : `-`
    },
    selfEmployerPayPerHour (vacancie) {
      if (!vacancie) {
        return ''
      }
      return vacancie.selfEmployerHourlyPay
    },
    maxPriceOfHour (vacancie) {
      if (vacancie.regionSetting && vacancie.regionSetting.length) {
        const regionSetting = vacancie.regionSetting[0]
        if (regionSetting.hasFixedPay) {
          return '-'
        } else if (regionSetting.maxPay) {
          return `${regionSetting.maxPay} ₽`
        }
      }
      return '-'
    }
  }
}
</script>

<style lang="sass" scoped>
  $countColor: #A7A7A7
  $fontWeightBold: 800

  .vacancies__table
    font-family: $mainFont
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 15px
    &__employee
      padding-right: 10px
    &__vacancy
      font-weight: 600

    &__profession
      color: rgba(0, 0, 0, 0.60)

    &__pay
      font-weight: $fontWeightBold
      text-align: right

</style>
