<template>
  <div
    :class="{
      'app-circle-step_state_active': isActive
    }"
    class="app-circle-step"
  >
    {{ num }}
  </div>
</template>
<script>
export default {
  name: 'AppCircleStep',
  props: {
    num: {
      type: [String, Number],
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="sass" scoped>
.app-circle-step
  display: flex
  justify-content: center
  align-items: center
  width: 38px
  height: 38px
  border-radius: 18px
  background: #fff
  color: #8BD118
  border: 1px solid #8BD118
  cursor: pointer
  @include fontStyle(600, 18px)

  &_state_active
    background: #8BD118
    color: #fff
</style>
