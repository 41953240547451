<template>
  <v-text-field
    :id="keyUpTargetId"
    v-model="searchQuery"
    :label="label"
    single-line
    :loading="loading"
    clearable
    rounded
    outlined
    dense
    hide-details
    color="success"
    @click:clear="onClearSearch"
    @change="onSelectedModelListListChange"
    @input="onInput"
  />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'WBSearchQueryFilter',
  props: {
    proceedFilter: {
      type: Function
    },
    remoteOptionValue: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  data () {
    return {
      keyUpTargetId: 'wbdSearchQueryEltRef',
      searchQuery: null,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted () {
    window.addEventListener('keyup', (event) => {
      if (!(event && event.target && event.target.id === this.keyUpTargetId)) {
        return
      }
      if (event.keyCode === 13) {
        event.preventDefault()
        if (this.proceedFilter) {
          this.proceedFilter()
        }
      }
    })
  },
  created () {
    if (this.remoteOptionValue) {
      this.searchQuery = this.remoteOptionValue
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    onClearSearch () {
      this.searchQuery = null
      this.$emit(
        'onSearchQueryFilterCleared',
      )

      this.AppLogger.info('onClearSearch')
      // this.eventsBus.$emit(this.eventNames.SEARCH_FILTER_RESET)
    },
    onInput () {
      console.log(this.searchQuery)
      this.onSelectedModelListListChange()
    },
    onSelectedModelListListChange () {
      this.$emit(
        'onSearchQueryFilterChange',
        this.searchQuery
      )
    },
  }
}
</script>
