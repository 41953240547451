<template>
  <div class="vacancies-modal-from-step-main">
    <div style="margin: 24px auto">
      <div style="text-align: center;margin: 24px auto">
        <span style="display: block; font-size: 0.9em">
          Здесь опишите важную информацию для соискателя, он увидит ее при прибытии на смену.
          Обращайтесь к соискателю лично, и будьте вежливы.
          Например: На этой смене, нам важно, чтобы вы были всегда вежливы с
          покупателями и помогали им по любым вопросам. Спасибо!
        </span>
        <span>
          (Не более 250 символов)
        </span>
      </div>
      <AppTextArea
        v-model="currentDescription"
        placeholder="Введите важную информацию для соискателя"
        :onChange="onChange"
        :size="'medium'"
        hideClose
        @input="onInput"
      />
    </div>
  </div>
</template>
<script>
import AppTextArea from '@/components/common/simple/AppTextArea'

export default {
  name: 'VacanciesModalFormStepInformation',
  components: {
    AppTextArea,
  },
  props: {
    vacancieCardData: {
      type: Object,
      default () {
        return {}
      }
    },
    getParamsFlag: {
      type: Boolean,
      default: false
    },
    vacancyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentDescription: '',
    }
  },
  computed: {
    formValid () {
      return this.currentDescription && this.currentDescription.length > 0
    }
  },
  watch: {
    getParamsFlag (flag) {
      if (flag) {
        this.$emit('onGetParamsQuestions', true)
      }
    }
  },
  created () {
    if (this.vacancieCardData && this.vacancieCardData.id && this.vacancieCardData.message) {
      this.currentDescription = this.vacancieCardData.message
    }
  },
  methods: {
    onInput (data) {
    },
    onChange ($event) {
    },
    onClear (ndx, id) {

    }
  }
}
</script>
<style>
</style>
