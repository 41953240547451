<template>
  <div
    v-if="enabled"
    class="app-alert"
    :class="customClass"
  >
    <IconClose
      :fill="'red'"
      :width="16"
      :height="16"
      class="modal__header__close icon-close"
      @click.native="close"
    />
    <span style="font-size: 0.85em">
      {{ message }}
    </span>
    <div style="width: 100% !important; text-align: left; margin-top: 4px">
      <AppButton
        style="display: block !important; float: left"
        class="button__notification"
        @click.native="proceed"
      >
        <span style="font-size: 0.85em">
          {{ actionName }}
        </span>
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/simple/AppButton'
import IconClose from '@/components/common/icons/IconClose'

export default {
  name: 'AppFloatingModal',
  components: { AppButton, IconClose },
  props: {
    enabled: {
      type: Boolean
    },
    message: {
      type: String
    },
    customClass: {
      type: Array
    },
    actionName: {
      type: String
    },
    actionCallback: {
      type: Function
    }
  },
  methods: {
    close () {
      this.$emit('appFloatingModalClose')
    },
    proceed () {
      if (this.actionCallback) {
        this.actionCallback()
      } else {
        this.close()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/create-offer-modal'
</style>

<style type="text/scss" scoped>
 .app-alert {
   color: white;
   background: #4B4B4B;
   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
   border-radius: 20px 20px 20px 0;
   padding: 16px;
   position: absolute;
   left: 10%;
   bottom: 100%;
   min-width: 280px;
   max-width: 420px;
 }
 .app-alert-right {
   border-radius: 20px 20px 0 20px;
   bottom: 100%;
   left: -300px;
   max-width: 320px;
 }
 .icon-close {
   position: absolute;
   right: 10px;
   top: 10px;
 }
</style>
