<template>
  <div class="vacancy__filter-top">
    <div
      class="filter-top__element filter-button"
      @click="createOffer"
    >
      <IconPlus style="width: 32px;" />
    </div>

    <div class="filter-top__left-group">
      <span
        v-for="item in statusGroups"
        :key="item.value"
        class="filter-top__element__text"
        :class="{
          'filter-top__element__text_active': filters.statusGroup === item.value,
          'filter-top__element__margin_right': item.hasMargin
        }"
        @click="changeStatus('active')"
      >{{ item.label }} ({{ item.count }})</span>
    </div>

    <div
      v-if="!isEdit"
      class="filter-top__element"
      @click="$emit('edit')"
    >
      <IconPanEdit />
      <span class="filter-top__element__element-text office__text-management">
        {{ $t('vacancies.controls.title') }}
      </span>
    </div>

    <div
      v-else
      class="filter-top__element"
    >
      <span
        class="filter-top__element__element-text office__text-management"
        @click="$emit('edit')"
      >
        {{ $t('vacancies.controls.ok') }}
      </span>
      <span
        class="filter-top__element__element-text office__text-management"
        @click="$emit('deactivate')"
      >
        {{ $t('vacancies.controls.deactivate') }}
      </span>
      <span
        class="filter-top__element__element-text office__text-management filter-top__element__element-text_warm"
        @click="$emit('openModal', modalName.removeOffer)"
      >
        {{ $t('vacancies.controls.delete') }}
      </span>
    </div>

    <component
      :is="layout.label"
      v-for="layout in layouts"
      :key="layout.value"
      class="filter-top__element__icon"
      :class="layout.class"
      :isActive="currentLayout === layout.value"
      @click="$emit('changeLayout', layout.value)"
    />
    <!--    <IconGrid class="filter-top__element__icon office__icon__grid" />

    <IconTable class="filter-top__element__icon office__icon__table" />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import IconGrid from '@/components/common/icons/IconGrid'
import IconTable from '@/components/common/icons/IconTable'
import { modalName } from '@/views/PageOfferList'

export default {
  name: 'VacancyFilterBarHeader',
  components: { IconTable, IconGrid, IconPanEdit, IconPlus },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    isScroll: {
      type: Boolean,
      required: false,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    currentLayout: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      modalName,
      statusGroups: [
        { label: this.$t('vacancies.filters.active'), value: 'active', count: '0' },
        { label: this.$t('vacancies.filters.total'), value: 'draft', count: '0', hasMargin: true },
        { label: this.$t('vacancies.filters.inactive'), value: 'inactive', count: '0' },
      ],
      layouts: [
        { label: IconGrid, value: 'grid', class: 'office__icon__grid' },
        { label: IconTable, value: 'table', class: 'office__icon__table' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      tabsCountData: 'vacancy/getTabsCountData'
    })
  },
  watch: {
    tabsCountData (data) {
      this.statusGroups[0].count = data.activeCount
      this.statusGroups[1].count = data.inactiveCount
      this.statusGroups[2].count = data.totalCount
    }
  },
  created () {
    this.statusGroups = [
      { label: this.$t('vacancies.filters.active'), value: 'active', count: this.tabsCountData.activeCount },
      { label: this.$t('vacancies.filters.inactive'), value: 'inactive', count: this.tabsCountData.inactiveCount },
      {
        label: this.$t('vacancies.filters.total'),
        value: 'draft',
        count: this.tabsCountData.totalCount, hasMargin: true
      },
    ]
  },
  methods: {
    changeStatus (type) {
      this.$emit('changeStatus', type)
    },
    changeOffer (type) {
      this.$emit('changeOffer', type)
    },
    createOffer () {
      this.$emit('createOffer')
    },
  }
}
</script>

<style lang="sass" scoped>
  .vacancy__filter-top
    display: flex
    align-items: center
    height: 45px

  .filter-top, .filter-bottom


    &__left-group, &__right-group
      margin-right: 30px

</style>
