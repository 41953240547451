<template>
  <div class="vacancies-modal-form-header">
    <div class="vacancies-modal-form-header__steps">
      <AppCircleStep
        v-for="(step, ndx) in steps"
        :key="ndx"
        style="margin-right: 10px;"
        :is-active="ndx === activeStep"
        :num="step"
        @click.native="$emit('changeStep', ndx)"
      />
    </div>
    <div class="vacancies-modal-form-header__title">
      {{ activeTitle }}
    </div>
  </div>
</template>
<script>
import AppCircleStep from '@/components/common/AppCircleStep'

export default {
  name: 'VacanciesModalFormHeader',
  components: {
    AppCircleStep
  },
  props: {
    steps: {
      type: Array,
      default () {
        return [1, 2, 3, 4]
      }
    },
    activeStep: {
      type: Number,
      default: 0
    },
    activeTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="sass">
  .vacancies-modal-form-header
    display: flex
    align-items: center
    padding: 15px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

    &__steps
      display: flex
      justify-content: space-between
      // width: 140px

    &__title
      @include fontStyle(800, 20px)
      margin-left: 20px

</style>
