<template>
  <tr>
    <td>{{ getLocalityTitle }}</td>
    <td>{{ vacancyRegionSetting.hourlyPay }}</td>
    <td>
      {{ parseFloat(vacancyRegionSetting.hourlyPay) >0
        && regionMinPayWithTax ? `${regionMinPayWithTax} руб/ч` : `` }}
    </td>
    <td>{{ vacancyRegionSetting.selfEmployerHourlyPay }}</td>
    <td>
      {{ regionSelfEmploymentMinPayWithTax ? `${regionSelfEmploymentMinPayWithTax} руб/ч` : `` }}
    </td>
    <td>
      <v-btn
        icon
        x-small
        color="error"
        @click="$emit('onDeleteEntry', vacancyRegionSetting)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import taxMixin from '@/mixins/taxMixin.vue'

export const RegionTitleSuffix = '(Все населенные пункты)'

export default {
  name: 'VacancyProfessionRegionSettingItem',
  mixins: [taxMixin],
  props: {
    vacancyRegionSetting: {
      type: Object
    },
  },
  data () {
    return {
    }
  },
  computed: {
    regionMinPayWithTax () {
      return parseFloat(this.calculateAmountTax(parseFloat(this.vacancyRegionSetting.hourlyPay))) + 1
    },
    regionSelfEmploymentMinPayWithTax () {
      const val = parseFloat(this.vacancyRegionSetting.selfEmployerHourlyPay)
      if (isNaN(val) || !val) {
        return 0
      }
      return val + 1
    },
    isNew () {
      return !this.vacancyRegionSetting.professionRegionSetting ||
        !this.vacancyRegionSetting.professionRegionSetting.id
    },
    getLocalityTitle () {
      const { professionRegionSetting } = this.vacancyRegionSetting
      if (!professionRegionSetting || !professionRegionSetting.id) {
        return null
      }

      const { region, locality } = professionRegionSetting
      if (locality) {
        // return `${locality.title} (${region.title})`
        return `${locality.title}`
      }
      return `${region.title} ${RegionTitleSuffix}`
    },
  },
  created () {
    // TODO check
  },
  methods: {
    calculateAmountTax (amount) {
      const val = parseFloat(amount)
      if (isNaN(val) || !val) {
        return 0
      }
      return (this.calculateTax(val) + (val)).toFixed(2)
    },
  }
}
</script>
<style lang="sass">
.panel-min-height
  min-height: 40px
.error-input-wrapper
  color: red
  font-size: 0.6em
  position: absolute
  bottom: -9px
  left: 10px
.on-field-error
  border-color: red !important
.text-description-title
  font-style: normal
  font-weight: 800
  font-size: 14px
  line-height: 17px
  color: #000000
  padding-left: 60px
.text-description
  font-size: 14px
  line-height: 17px
  color: #000000
  opacity: 0.8
.modal-row
  margin: 1px 0 0 !important
  padding: 0!important
.modal-row-separator
  margin: 4px auto !important
.modal-row-item
  min-width: 100px
  padding-left: 15px
.payment-label-item
  margin-bottom: 4px
.row-item-relative
  position: relative
.vacancies-modal-from-step-main
  &__field-right
    display: flex
    justify-content: space-between
    align-items: center
    margin: 20px 0
    position: relative

    &__title-require
      min-width: 120px
      position: absolute
      top: -61px
      @include fontStyle(500, 13px)
      opacity: .3

    &__title
      @include fontStyle(500, 14px)

    &__title-question
      @include fontStyle(800, 16px)
      opacity: .3

    &__input
      width: 70px
      margin: 0 15px

    &__description
      width: 65px
      @include fontStyle(500, 12px)
      opacity: .2

</style>
